import { useQuery } from '@apollo/client';
import {
  Box,
  Grid,
  Hidden,
  Paper,
  Typography,
  useMediaQuery
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import trackOrder from '../../assets/images/marketOrder/trackOrder.png';
import { TRACK_ORDER } from '../../queries/orders';
import MainContent from '../customComponents/mainContent';

import {
  BottomPaper,
  CTextField,
  ContentBox,
  EmptyBox,
  HeaderTypo,
  IdPaper,
  SubHeader,
  TopPaper, TrackGrid,
  TrackImg,
  TrackOrderButton, TrackOrderButtonText, TrackText,
  Typo, PackagePaper,
  OrderPackTitle,
  OrderPackSub,
  PackageColor
} from './trackOrderContainer.styles';
import TrackOrderStepper from './trackOrderStepper';
import { PackageIcon, ParcelTrack } from '../../assets/svgs';
import AffiliateMenu from '../navigation/affiliateNavigation/affiliateMenu';

export default function TrackOrderContainer() {
  const location = useLocation();
  const state = location?.state;

  const [textInput, setTextInput] = useState('');
  const [variable, setVariable] = useState(undefined);
  const [activeStep, setActiveStep] = useState(0);
  const isSmall = useMediaQuery('(max-width: 991px)');
  const [steps, setSteps] = useState([]);
  const [displayTrack, setDisplayTrack] = useState(0);
  const [orders, setOrders] = useState([]);
  const [orderPackage, setOrderPackage] = useState([]);
  const [closeMenu, setCloseMenu] = useState(false);

  useEffect(() => {
    if (state) {
      setTextInput(state.orderId);
      setVariable(state.orderId);
    }
  }, []);

  const {
    loading, error, data
  } = useQuery(TRACK_ORDER, {
    fetchPolicy: 'no-cache',
    variables: { orderId: +variable }
  });

  const handleTrackOrderButton = () => {
    setVariable(textInput);
    setDisplayTrack(0);
  };

  const returnTextField = () => (
    <CTextField
      variant="outlined"
      placeholder="Enter Order ID"
      value={textInput}
      size="large"
      name="orderId"
      onChange={(event) => setTextInput(event.target.value)}
      type="number"
      fullWidth
      InputProps={{
        style: { fontSize: isSmall ? '2rem' : '.9rem', borderRadius: '.5rem', height: isSmall && '100px' }
      }}
      InputLabelProps={{
        style: { fontSize: isSmall ? '1.1rem' : '.8rem', color: '#606060' },
      }}
    />
  );

  const initialSteps = [
    {
      header: 'Order Placed',
      title: 'Your order has been placed! Sit back and relax while we take care of the rest.',
      date: ''
    },
    {
      header: 'Order Approved',
      title: 'Congratulations! Your order has been approved and is now on its way to been Sorted.',
      date: ''
    },
    {
      header: 'Order Package Sorted',
      title: 'Hi, your package order has been sorted based on its destination and other factors such as weight, size and delivery speed.',
      date: ''
    },
    {
      header: 'Order Ready for Dispatch',
      title: "Hi, your package will be on its way soon, it's ready for delivery.",
      date: ''
    },
    {
      header: 'Order Package Dispatched',
      title: 'Hi, Your package has been delivered to the "recipient\'s address or a designated pickup location".',
      date: ''
    },
  ];

  const handlePartialFulfilled = () => initialSteps.splice(4, 0);

  const handleSteps = (item) => {
    const {
      orderDispatched, orderReadyForDispatch, orderSorted,
      orderApproved, orderPlaced, orderRejected
    } = item || {};

    let color;
    let sub;

    if (orderRejected) {
      color = '#ff0000';
      sub = 'Order Rejected';
      setActiveStep(1);
      setSteps([
        {
          header: 'Order Placed',
          title: 'Your order has been placed! Sit back and relax while we take care of the rest.',
          date: orderPlaced
        },
        {
          header: 'Order Rejected',
          title: 'Your order has been rejected.',
          date: orderRejected
        }
      ]);
    } else {
      const updatedSteps = initialSteps.map((step) => {
        switch (step.header) {
          case 'Order Placed':
            return { ...step, date: orderPlaced };
          case 'Order Approved':
            return { ...step, date: orderApproved };
          case 'Order Package Sorted':
            return { ...step, date: orderSorted };
          case 'Order Ready for Dispatch':
            return { ...step, date: orderReadyForDispatch };
          case 'Order Package Dispatched':
            return { ...step, date: orderDispatched };
          default:
            return step;
        }
      });
      setSteps(updatedSteps);
      if (orderDispatched) {
        color = '#5465ff';
        sub = 'Order Dispatched';
        setActiveStep(4);
      } else if (orderReadyForDispatch) {
        color = '#00bfff';
        sub = 'Ready for Dispatch';
        setActiveStep(3);
      } else if (orderSorted) {
        color = '#9747ff';
        sub = 'Sorted';
        setActiveStep(2);
      } else if (orderApproved) {
        color = '#008000';
        sub = 'Order Approved';
        setActiveStep(1);
      } else if (orderPlaced) {
        color = '#f4bb47';
        sub = 'Order Placed';
        setActiveStep(0);
      }
    }
    return { color, sub };
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (data?.trackOrder && !error) {
      setSteps(initialSteps);
      setOrders(data?.trackOrder);
      handlePartialFulfilled();
      const packages = data?.trackOrder.map((item, index) => {
        const id = index + 1;
        const { color, sub } = handleSteps(item);
        const packageItem = {
          id, name: `Order Package ${id}`, color, item,
          sub
        };
        return packageItem;
      });
      setOrderPackage(packages);
    }
  }, [loading, data]);

  const handleDisplayStepper = (id, item) => {
    setDisplayTrack(displayTrack === id ? 0 : id);
    if (item) handleSteps(item);
  };

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  return (
    <MainContent>
      <ContentBox container spacing={2}>
        <Hidden mdDown>
          <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
        </Hidden>
        <Grid item xs={12} md={closeMenu ? 10.8 : 9.3}>
          <TopPaper container component={Paper} elevation={0}>
            <TrackGrid item lg={7} xs={12}>
              <HeaderTypo>Track Your Order</HeaderTypo>
              <SubHeader>Enter your Order ID below to start tracking your orders</SubHeader>
              <TrackText>Order ID</TrackText>
              <Grid container spacing={3}>
                <Grid item lg={6} xs={12}>
                  {returnTextField()}
                </Grid>
                <Grid item lg={5} xs={12}>
                  <TrackOrderButton onClick={handleTrackOrderButton}>
                    <TrackOrderButtonText>Track Order</TrackOrderButtonText>
                  </TrackOrderButton>
                </Grid>
              </Grid>
            </TrackGrid>
            <Hidden mdDown>
              <Grid item lg={4}>
                <TrackImg src={trackOrder} alt="Track Order" />
              </Grid>
            </Hidden>
          </TopPaper>

          {(!loading && !error && orders.length) ? (
            <>
              <IdPaper>
                <Box>
                  <Typography
                    style={{
                      fontSize: isSmall ? '2rem' : '1.313rem', fontWeight: '700', marginBottom: '.5rem'
                    }}
                    data-testid="orderId"
                  >
                    Order ID
                  </Typography>
                  <Typography
                    style={{
                      fontSize: isSmall ? '2.5rem' : '1.5rem', fontWeight: '700', marginBottom: '1rem'
                    }}
                  >
                    {`LIFSTR 0${variable}`}
                  </Typography>
                </Box>
              </IdPaper>

              {orderPackage.map(({
                name, sub, id, color, item
              }) => (
                <PackagePaper key={id} isOpen={(displayTrack === id && true)} rejected={item?.orderRejected}>
                  <Grid container>
                    <Grid item xs={0.6}>
                      <PackageIcon style={{ fontSize: '3.5rem' }} />
                    </Grid>
                    <Grid item xs={10.9}>
                      <OrderPackTitle active={(displayTrack === id && true)}>
                        {name}
                      </OrderPackTitle>
                      {displayTrack !== id && (
                        <OrderPackSub>
                          <PackageColor color={color} />
                          {sub}
                        </OrderPackSub>
                      )}
                    </Grid>
                    <Grid item xs={0.5} style={{ textAlign: 'center' }}>
                      <Box style={{ marginTop: '.6rem' }}>
                        {displayTrack === id
                          ? (
                            <KeyboardArrowUpIcon
                              style={{
                                fill: '#235a91', fontSize: '2rem', cursor: 'pointer'
                              }}
                              onClick={() => handleDisplayStepper(id, item)}
                            />
                          )
                          : (
                            <KeyboardArrowDownIcon
                              style={{
                                fill: '#235a91', fontSize: '2rem', cursor: 'pointer',
                              }}
                              onClick={() => handleDisplayStepper(id, item)}
                            />
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                  {displayTrack === id && <hr style={{ marginTop: '1rem', border: '0.5px solid #f5f5f5' }} />}
                  {displayTrack === id && (
                    <TrackOrderStepper
                      activeStep={activeStep}
                      steps={steps}
                    />
                  )}
                </PackagePaper>
              ))}
            </>
          ) : (
            <BottomPaper elevation={0}>
              <EmptyBox>
                <ParcelTrack style={{ fontSize: '11rem' }} />
                <Typo>Nothing to display yet!</Typo>
                <TrackText>
                  Kindly enter your order number to be able to track your order
                </TrackText>
              </EmptyBox>
            </BottomPaper>
          )}
        </Grid>
      </ContentBox>
    </MainContent>
  );
}

TrackOrderContainer.propTypes = {
};

TrackOrderContainer.defaultProps = {
};

import {
  Grid, Paper, TableCell, TableBody, Typography, Button,
  Dialog,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const TableContainerWrapper = styled(Paper)`
  border-radius: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 24px 18px 21px 24px;
`;

export const TableGrid = styled(Grid)`
  overflow-x: auto;
  padding-bottom: 4rem;
`;

export const TableComponent = styled('div')`
  width: 100%;
`;

export const MainTableHead = styled(TableRow)`
   border: 0;
`;

export const MainTableBody = styled(TableBody)`
  width: 100%;
`;

export const TableHeader = styled(TableCell)`
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.8;
  padding: 10px;
  border-bottom: 0;
  color: #303030;
  background: #F3F9FF;
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(min-width: 992px) {
    &:nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7), :nth-child(8) {
      min-width: 12.5%;
    }
  }
`;

export const TitleGridContainer = styled(Grid)`
  margin: 2rem 1rem 2rem 0;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const PrimaryTitle = styled(Typography)`
  font-size: 3rem;
  font-weight: 700;
  color: #24292E;

  @media(min-width: 992px) {
    font-size: 1.5rem;
  }
`;

export const AddCategory = styled(Button)`
  width: 11.125rem;
  height: 45px;
  background: #235A91;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 500;
  font-size: 12px;
  margin-left: 5px;

  &:hover {
    background-color: #235A91;
    color: #ffffff;
  }

  &.MuiButton-root.Mui-disabled {
    color: #606060;
    background: #F4F5F7 !important;
    border: 1px solid #C0C0C1;
  }
`;

export const TextWrapper = styled(Typography)`
  font-weight: 700;
  font-size: .875rem;
`;

export const FilterGrid = styled(Grid)`
  padding: 19px 15px 19px 24px;
  align-items: center;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  margin-bottom: 20px;
`;

export const NumberText = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: #606060;
  padding: 13.5px 20px;
  border-radius: 5px;
  background: #F3F9FF;
  text-align: center;
`;

export const ExportText = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
  margin-left: 10px;
  color: #16181B;
  opacity: .5;
`;

export const ExportGrid = styled(Grid)`
  width: 6.8rem;
  height: 2.938rem;
  background: #FFFFFF;
  border: 1px solid #E7E8E9;
  border-radius: 8px;
  display: flex;
  padding: .8rem;
  margin-left: auto;
  cursor: pointer;
`;

export const Description = styled(Typography)`
  font-size: .875rem;
  color: #606060;
  font-weight: 400;
  padding-top: 1rem;
`;

export const Title = styled(Typography)`
  font-size: 1.125rem;
  color: #303030;
  font-weight: 700;
`;

export const CDialog = styled(Dialog)`
  & .MuiDialog-paperScrollPaper {
    border-radius: 12px;
    padding: .8rem;
    max-height: 31.438rem;
    width: 28.813rem;
    box-shadow: 0px 2.96px 66.64px 0px rgba(163, 171, 185, 0.12);
  }
`;

export const CancelButton = styled(Button)`
  border-radius: 5px;
  text-align: center;
  font-size: .875rem;
  font-weight: 700;
  border: 1.5px solid #235a91;
  width: 11.125rem;
  height: 3rem;
  color: #235a91;
`;

export const RemoveButton = styled(Button)`
  border-radius: 5px;
  text-align: center;
  font-size: .875rem;
  font-weight: 700;
  background: #235a91;
  width: 11.125rem;
  height: 3rem;
  color: #ffffff;

  &:hover {
    background: #235a91;
    color: #ffffff;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem } from '@mui/material';
import CustomNewTextField from '../../shared/CustomNewTextField';
import CustomButton from '../../customComponents/customButton';
import {
  FiltersGrid
} from './paymentFilter.styles';

const PaymentFilter = ({
  state, handleFilterChange, handleSetFilter,
}) => {
  const status = [
    { name: 'Pending', value: 'PENDING' },
    { name: 'Paid', value: 'RAID' },
  ];


  const textFields = [
    { name: 'orderNumber', label: 'Order Number' },
    { name: 'invoiceNumber', label: 'Invoice Number' },
    { name: 'paymentStatus', label: 'Payment Status', options: status },
    { name: 'affiliateName', label: 'Enter affiliate' },
  ];

  return (
    <Grid container xs={12}>
      <FiltersGrid container item justifyContent="flex-end">
        {
          textFields.map((field) => {
            const { name, label, options } = field;
            if (['paymentStatus'].includes(name)) {
              return (
                <CustomNewTextField
                  key={name}
                  field={{ name, label }}
                  state={state}
                  select
                  handleChange={handleFilterChange}
                  marginLeft="9.2rem"
                  borderRadius="0.3rem"
                  style={{ width: '19rem' }}
                >
                  {options.map(({ name: fName, value }) => (
                    <MenuItem key={value} value={value}>
                      {fName}
                    </MenuItem>
                  ))}
                </CustomNewTextField>
              );
            } return (
              <CustomNewTextField
                key={name}
                field={{ name, label }}
                state={state}
                borderRadius="0.3rem"
                style={{ width: '19rem' }}
                handleChange={handleFilterChange}
              />
            );
          })
        }
        <CustomButton
          style={{
            width: '7rem', height: '2.95rem', fontWeight: '700', fontSize: '0.75rem',
            borderRadius: '0.3rem'
          }}
          onClick={handleSetFilter}
        >
          Set Filter
        </CustomButton>
      </FiltersGrid>
    </Grid>
  );
};

PaymentFilter.propTypes = {
  state: PropTypes.shape(Object),
  handleFilterChange: PropTypes.func.isRequired,
  handleSetFilter: PropTypes.func.isRequired,
};

PaymentFilter.defaultProps = {
  state: {},
};

export default PaymentFilter;

import {
  Dialog, Grid, Typography, DialogContent
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';

export const WrapperGrid = styled(Grid)`
  width: 32vw;
  padding: 2rem 1.5rem 0rem 1.5rem;
  position: relative;
`;

export const PaymentDialogP = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px;
    padding-bottom: 1.5rem;
  }
`;

export const AdornmentStyled = styled('div')`
  font-size: 14px;
  padding-left: 1rem;
  margin-right: -1rem;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const HeaderGrid = styled(Grid)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Header = styled(Typography)`
   font-weight: 700;
  font-size: 20px;
  color: #303030;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  width: 22px;
`;

export const FooterStyled = styled('div')`
  display: flex;
  gap: 1.2rem;
  justify-content: flex-end;
  padding: 2.5rem 0 0 0;
  width: 100%;
`;

export const DialogContainer = styled(Dialog)`
  & .MuiDialog-paper {
    border-radius: 12px !important;
  }
`;

export const CardTitle = styled('span')`
  font-size: 18px;
  font-weight: 700;
  color: #303030;
`;

export const DialogContentStyled = styled(DialogContent)`
  min-width: 40rem !important;
  height: auto;
  box-shadow: none;
`;

export const TitleDesc = styled(Typography)`
  color: #606060;
  font-size: 14px;
  font-weight: 400;
`;


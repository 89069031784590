import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  OverdueDialog, TextBox, LoadingGif, TitleText, PayNowButton,
  DialogTitleContainer, StyledCloseIcon, SubTitleText,
} from './overdueModal.styles';
import loading from '../../../assets/images/loading.gif';
import PaymentRepayDialog from '../../creditWallet/repayDialog';
import currencyFormatter from '../../shared/currencyFormatter';

const OverdueModal = ({ open, handleClose, amount }) => {
  const [openRepay, setOpenRepay] = useState(false);

  const handleRepayDialog = () => {
    setOpenRepay(true);
    handleClose();
  };

  const handleCopyNumber = () => {
    navigator.clipboard.writeText('+234 905 579 3553')
      .then(() => {
        toast.success('Phone number copied to clipboard!');
      })
      .catch(() => {
        toast.error('Failed to copy phone number!');
      });
  };

  return (
    <>
      <OverdueDialog
        open={open}
        keepMounted
        onClose={handleClose}
        maxWidth="sm"
        fullWidth
        filesLimit={1}
      >
        <DialogTitleContainer id="alert-dialog-slide-title">
          <StyledCloseIcon onClick={handleClose} />
        </DialogTitleContainer>

        <TextBox>
          <LoadingGif src={loading} alt="loading" />
          <TitleText>
            Your Credit Repayment is Overdue!
          </TitleText>
          <SubTitleText>
            You have an overdue credit payment of
            {' '}
            <span style={{ fontWeight: '700' }}>
              {`₦${currencyFormatter(+amount)}`}
            </span>
            .
            {' '}
            <br />
            Please make your payment to continue shopping with OGApharmacy. Need help? Contact our support line
            <br />
            <span
              onClick={handleCopyNumber}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleCopyNumber();
                }
              }}
              role="button"
              tabIndex="0"
              style={{ fontWeight: '700', color: 'rgba(52, 152, 219, 1)', cursor: 'pointer' }}
            >
              +234 905 579 3553
            </span>
          </SubTitleText>

          <PayNowButton onClick={handleRepayDialog}>Pay Now</PayNowButton>
        </TextBox>
      </OverdueDialog>

      <PaymentRepayDialog
        open={openRepay}
        handleClose={() => setOpenRepay(false)}
      />
    </>
  );
};

OverdueModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired
};

export default OverdueModal;

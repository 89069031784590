import React, { useEffect, useState } from 'react';
import { TableBody, TableContainer, Table, Hidden } from '@mui/material';
import { useQuery } from '@apollo/client';
import MainContent from '../customComponents/mainContent';
import ProductsTableLoader from '../customComponents/loaders/productsTableLoader';
import {
  TableHeader,
  GridContainer,
  MainTableHead,
  PaperWrapper,
  TableGrid,
  FooterWrapper,
  SearchFlex,
  TitleGrid,
  TitleTextGridContainer,
  PrimaryTitle,
  SubTitle,
  MainContainer
} from './uploads.styles';
import { FilledCustomButton } from '../customComponents/customButton';
import CustomSearchField from '../shared/CustomSearchField';
import TablePagination from '../shared/tablePagination';
import CustomDateRanges from '../shared/customDateRanges/customDateRanges';
import ReturnRow from './returnRow';
import { GET_ALL_UPLOADS } from '../../queries/reports';
import {
  BodyCell,
  MainTableRow,
} from './returnRow.styles';
import { addHyphen } from '../shared/methods';
import AffiliateMenu from '../navigation/affiliateNavigation/affiliateMenu';

const headers = [
  'S/N',
  'Date & Time Uploaded',
  'Name of Upload',
  'Initiated By',
  'Checking Status',
  'Action'
];

const initialState = {
  searchText: '',
  search: ''
};

const Uploads = () => {
  const [state, setState] = useState(initialState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [closeMenu, setCloseMenu] = useState(false);

  const { searchText } = state;

  useEffect(() => {
    if (searchText && searchText.length >= 3) {
      setState({ ...state, search: searchText });
    } else if (!searchText) {
      setState({ ...state, search: '' });
    }
  }, [searchText]);
  const handleSearch = (text) => {
    setState({ ...state, searchText: text });
  };

  const returnHeaders = () => headers.map((header) => (
    <TableHeader
      key={header}
      data-testid={`mp-uat-uploads-${addHyphen(header)}`}
    >
      {header}
    </TableHeader>
  ));

  const handleSubmit = (dateRange) => {
    const { dateFrom, dateTo } = dateRange;
    setState((_state) => ({
      ..._state,
      dateFrom,
      dateTo
    }));
  };

  const { loading, data, refetch } = useQuery(GET_ALL_UPLOADS, {
    variables: { ...state, pageCount, pageNumber },
    fetchPolicy: 'network-only'
  });

  const response = data?.allCsvUploads || [];
  const totalNumber = data?.csvUploadCount || 0;

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  return (
    <MainContent>
      <MainContainer container>
        <Hidden mdDown>
          <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
        </Hidden>
        <GridContainer container item xs={12} md={closeMenu ? 10.8 : 9.3}>
          <TitleGrid container item>
            <TitleTextGridContainer>
              <div>
                <PrimaryTitle variant="h5">All Uploads</PrimaryTitle>
                <SubTitle>View the progress of all your uploaded CSV</SubTitle>
              </div>
              <FilledCustomButton onClick={() => refetch()}>
                <img src="https://res.cloudinary.com/health-id/image/upload/v1702566077/tabler_refresh.svg" alt="" style={{ marginRight: '5px' }} />
                Refresh
              </FilledCustomButton>
            </TitleTextGridContainer>
          </TitleGrid>
          <PaperWrapper elevation={0}>
            <SearchFlex>
              <CustomSearchField
                name="search"
                value={searchText}
                placeholder="Search"
                handleChange={(e) => handleSearch(e.target.value)}
                style={{ width: '100%' }}
              />
              <div style={{ width: '16rem' }}>
                <CustomDateRanges
                  handleSubmit={handleSubmit}
                  styles={{ height: '2.9rem', border: '1px solid #bababa' }}
                />
              </div>
            </SearchFlex>
            <TableGrid item container>
              {loading ? (
                <ProductsTableLoader />
              ) : (
                <TableContainer>
                  <Table data-testid="mp-uat-uploads-table">
                    <MainTableHead>
                      {returnHeaders()}
                      <TableHeader />
                    </MainTableHead>
                    <TableBody>
                      {
                      response?.length
                        ? response?.map((item, index) => {
                          const idx = item?.id;
                          return <ReturnRow key={idx} row={item} sn={index + 1} />;
                        })
                        : (
                          <MainTableRow>
                            <BodyCell colspan={6} style={{ textAlign: 'center' }}>No Record Found</BodyCell>
                          </MainTableRow>
                        )
                    }
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </TableGrid>
            <FooterWrapper item container>
              {totalNumber > 0 && (
                <TablePagination
                  total={totalNumber}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              )}
            </FooterWrapper>
          </PaperWrapper>
        </GridContainer>
      </MainContainer>
    </MainContent>
  );
};

export default Uploads;

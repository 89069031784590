import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid, InputAdornment, Tooltip, tooltipClasses
} from '@mui/material';
import styled from 'styled-components';
import {
  CInputBase, Label, HelperText, CircularProgressLoader, HelpOutlineStyled, TooltipTitle, ToolTipContent,
} from './customInputBase.styles';
import { AmountWarningIcon } from '../../assets/svgs';

export const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#235A91',
    color: '#fff',
    fontSize: theme?.typography?.pxToRem(12),
    border: '1px solid #235a91',
    borderRadius: '8px',
    padding: '.5rem 0.5rem .5rem 1rem',
    minWidth: '21rem !important',
    marginLeft: '15rem',
    minHeight: '5rem',
    '&[x-placement*="top"] $arrow': {
      bottom: '-8px',
      left: '50%',
      borderWidth: '8px 8px 0',
      borderTopColor: '#235A91',
    },
  },
  '.MuiTooltip-arrow': {
    color: '#235A91',
    marginLeft: '-7.5rem'
  },
}));

const CustomInputBase = ({
  label, sideLabel, required, error, loading, sAdornment, helperText, isTooltip, tooltipDesc,
  createPromotion, greyLabel, tooltipHeader, tooltipPlacement, style, warmingMsg,
  ...props
}) => (
  <Grid item container direction="column">
    <Grid item container justifyContent="space-between">
      {label ? (
        <Label error={error} createPromotion={createPromotion} className="label" grey={greyLabel} style={style}>
          {label}
          {' '}
          {createPromotion ? (
            <span style={{ color: '#EE4223', fontSize: '16px' }}>{required && '*'}</span>
          )
            : required && <span style={{ color: 'red' }}>*</span>}

          {isTooltip && (
            <HtmlTooltip
              title={(
                <>
                  <TooltipTitle>{tooltipHeader || 'Quick Guide'}</TooltipTitle>
                  <ToolTipContent>{tooltipDesc}</ToolTipContent>
                </>
              )}
              arrow
              placement={tooltipPlacement}
            >
              <HelpOutlineStyled />
            </HtmlTooltip>
          )}
        </Label>
      ) : ''}
      {sideLabel ? <Label style={{ fontSize: '10px' }}>{sideLabel}</Label> : ''}
    </Grid>
    <CInputBase
      className={`mp-uat-${label.replace(/ /g, '-')}`}
      error={error}
      inputProps={{ 'data-testid': label }}
      {...props}
      startAdornment={sAdornment ? (
        <InputAdornment position="start">
          {sAdornment}
        </InputAdornment>
      ) : ''}
      endAdornment={loading ? (
        <InputAdornment position="end">
          <CircularProgressLoader
            disableShrink
            size={18}
            thickness={4}
          />
        </InputAdornment>
      ) : ''}
    />
    {helperText && error ? <HelperText>{helperText}</HelperText> : ''}
    {warmingMsg
      ? (
        <HelperText
          style={{
            color:
              warmingMsg === 'Invoice will be fully paid'
                ? '#008F3D'
                : warmingMsg === 'Invoice will be overpaid'
                  ? '#E74C3C'
                  : '#E79800',
            display: 'flex',
            gap: '0.4rem',
            marginTop: '0.3rem'
          }}
        >
          <AmountWarningIcon
            style={{
              fontSize: '1rem',
            }}
          />
          <span>{warmingMsg}</span>
        </HelperText>
      )
      : ''}
  </Grid>
);

CustomInputBase.propTypes = {
  label: PropTypes.string,
  sideLabel: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.bool,
  loading: PropTypes.bool,
  helperText: PropTypes.string,
  props: PropTypes.shape(Object),
  createPromotion: PropTypes.bool,
  isTooltip: PropTypes.bool,
  tooltipDesc: PropTypes.string,
  sAdornment: PropTypes.element,
  greyLabel: PropTypes.bool,
  tooltipHeader: PropTypes.string,
  tooltipPlacement: PropTypes.string,
  style: PropTypes.element,
  warmingMsg: PropTypes.string
};

CustomInputBase.defaultProps = {
  label: '',
  sideLabel: '',
  required: false,
  error: false,
  loading: false,
  helperText: '',
  createPromotion: false,
  props: {},
  isTooltip: false,
  tooltipDesc: '',
  sAdornment: null,
  greyLabel: false,
  tooltipHeader: '',
  tooltipPlacement: 'bottom',
  style: null,
  warmingMsg: ''
};

export default CustomInputBase;

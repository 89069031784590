import React, { useState, useEffect } from 'react';
import {
  Paper, TableContainer, TableBody, Grid, Table
} from '@mui/material';
import { useQuery } from '@apollo/client';
import { TableContainerWrapper, FooterGrid } from './payments.styles';
import ReturnRow from './returnRow';
import PaymentFilter from './paymentFilter';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import TablePagination from '../../shared/tablePagination';
import {
  TableComponent,
  TableColumnHeaders,
  TableHeader
} from '../../shared/tableGlobalStyle.styles';
import { MANAGE_USERS_ACCOUNT } from '../../../queries/manageAccounts';
import { TitleHeader } from '../paymentContainer.styles';
import { useStateValue } from '../../../providers/stateProvider';
import { JSONParse } from '../../../utils/json';

// const headers = [
//   { name: 'Created Date', width: '200px' },
//   { name: 'Business Name', width: '300px' },
//   { name: 'Account Type', width: '250px' },
//   { name: 'Location', width: '250px' },
//   { name: 'Status', width: '150px' },
//   { name: 'Action', width: '150px' }
// ];

const headers = [
  { name: 'S/N', width: '50px' }, { name: 'Affiliate Name', width: '180px' }, { name: 'Order Number', width: '70px' }, { name: 'Invoice', width: '100px' },
  { name: 'Amount Paid', width: '100px' }, { name: 'Invoice Number', width: '80px' }, { name: 'Payment Status', width: '80px' },
  { name: 'Credit Days', width: '70px' }, { name: 'Action', width: '180px' }
];

const Payments = () => {
  const initialFilterState = {
    orderNumber: '',
    invoiceNumber: '',
    affiliateName: '',
  };

  const [state, setState] = useState(initialFilterState);
  const [filterState, setFilterState] = useState(initialFilterState);
  const [pageCount, setPageCount] = useState(20);
  const [pageNumber, setPageNumber] = useState(1);
  const [accountType, setAccountType] = useState('Buyers');

  const [{ user: { session } }] = Object.values(useStateValue()) || {};
  const [tabVal, setTabVal] = useState('BUYER');

  useEffect(() => {
    if (session && session.businessuserSet && session.businessuserSet.length > 0) {
      const { businessuserSet: [{ meta }] } = session;
      const parsedMeta = JSONParse(meta) || {};
      const role = parsedMeta?.role;
      setTabVal(role === 'BUYER' ? 'BUYER' : 'SELLER');
      setAccountType(role === 'BUYER' ? 'Buyers' : 'Sellers');
    }
  }, [session]);

  const handleSetFilter = () => {
    setFilterState(state);
  };


  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ minWidth: width }}>
      {name}
    </TableHeader>
  ));

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setState((_state) => ({
      ..._state,
      [name]: value
    }));
  };


  const { loading, data, refetch } = useQuery(MANAGE_USERS_ACCOUNT, {
    fetchPolicy: 'no-cache',
    variables: {
      ...filterState,
      businessType: tabVal,
      pageCount,
      pageNumber
    }
  });

  const allBusinesses = data?.allBusinesses ?? [];
  const totalRecords = data?.allBusinessesTotalNumber ?? 0;

  return (
    <TableContainerWrapper component={Paper}>
      <Grid container xs={12}>
        <Grid item xs={12} style={{ marginLeft: 'auto' }}>
          <PaymentFilter
            state={state}
            handleFilterChange={handleFilterChange}
            handleSetFilter={handleSetFilter}
            tab={tabVal}
          />
        </Grid>
      </Grid>
      <TableContainer>
        {loading ? (
          <ProductsTableLoader />
        ) : (
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                <TableHeader />
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {allBusinesses.map((business, index) => (
                  <ReturnRow
                    key={business.id}
                    row={business}
                    index={index}
                    refetch={refetch}
                    tab={tabVal}
                  />
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        )}
      </TableContainer>
      <FooterGrid item container>
        <TablePagination
          total={totalRecords}
          pageCount={pageCount}
          setPageCount={setPageCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </FooterGrid>
    </TableContainerWrapper>
  );
};

export default Payments;

import React, { useRef, useState } from 'react';
import { Grid, InputAdornment } from '@mui/material';
import { useQuery } from '@apollo/client';
import Hidden from '@mui/material/Hidden';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '../../../assets/images/marketOrder/searchIcon.png';
import { useStateValue } from '../../../providers/stateProvider';
import ProductsTableLoader from '../../customComponents/loaders/productsTableLoader';
import MainContent from '../../customComponents/mainContent';
import CustomDateRanges from '../../shared/customDateRanges/customDateRanges';
import TablePagination from '../../shared/tablePagination';
import StatisticCard from '../shared/statisticCard';
import TitleGrid from '../shared/titleGrid';
import FilterPopper from './filters/filterPopper';
import {
  FooterGrid, EmptyStateGrid,
  ItemContainer,
  ItemsBox,
  MainContainer,
  OrderGridContainer,
  OrderText,
  SearchBox,
  SearchButton,
  SearchContainer,
  SearchImage,
  SearchTextField,
  StatisticsGrid,
  StatisticsGridContainer,
  NothingDisplay,
  NothingSubtitle
} from './order.styles';
import ReturnRow from './returnRow';
import {
  OrdersBag, ActiveCart, GrossIcon, OrderCompleted, EmptyState
} from '../../../assets/svgs';
import { BUYER_DASHBOARD_SUMMARY } from '../../../queries/orders';
import currencyFormatter from '../../shared/currencyFormatter';
import AffiliateMenu from '../../navigation/affiliateNavigation/affiliateMenu';

const Order = ({
  orders,
  totalCount,
  loading,
  handleSearch,
  pageNumber,
  pageCount,
  setPageCount,
  setPageNumber,
  handleSubmit,
  setFilter,
  orderId
}) => {
  const totalsContainer = useRef(null);
  const [status, setStatus] = useState('');
  const [filterButtonEl, setFilterButtonEl] = useState(null);
  const [{ user }, dispatch] = Object.values(useStateValue());
  const [closeMenu, setCloseMenu] = useState(false);
  const navigate = useNavigate();

  const { session } = user;
  const { isBuyer, isBuyerVerified } = session || null;
  const verifiedBuyer = isBuyer && isBuyerVerified;

  React.useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading: sumLoading, error, data } = useQuery(BUYER_DASHBOARD_SUMMARY, {
    fetchPolicy: 'cache-and-network',
  });

  const {
    activeOrders, completedOrderValue, grossOrderValue, totalOrders
  } = data?.buyerDashboardSummary || {};

  const parsedGrossValue = parseFloat(grossOrderValue?.toString()?.replace(/,/g, ''));
  const parsedCompletedValue = parseFloat(completedOrderValue?.toString()?.replace(/,/g, ''));

  const STATISTICS = [
    {
      id: 1, title: 'Total Orders', value: verifiedBuyer ? (sumLoading || error ? '0' : totalOrders) : '0'
    },
    {
      id: 2, title: 'Active Orders', value: verifiedBuyer ? (sumLoading || error ? '0' : activeOrders) : '0'
    },
    {
      id: 3, title: 'Gross Order Value', value: verifiedBuyer ? (sumLoading || error ? '₦0.00' : `₦${currencyFormatter(parsedGrossValue)}`) : '₦0.00'
    },
    {
      id: 4, title: 'Completed Order Value', value: verifiedBuyer ? (sumLoading || error ? '₦0.00' : `₦${currencyFormatter(parsedCompletedValue)}`) : '₦0.00'
    },
  ];

  const returnStatIcon = (id) => {
    switch (id) {
      case (1):
        return <OrdersBag style={{ fontSize: '2.5rem' }} />;
      case (2):
        return <ActiveCart style={{ fontSize: '2rem' }} />;
      case (3):
        return <GrossIcon style={{ fontSize: '2rem' }} />;
      case (4):
        return <OrderCompleted style={{ fontSize: '2.5rem' }} />;
      default:
        return <OrdersBag style={{ fontSize: '2rem' }} />;
    }
  };

  const isLarge = useMediaQuery('(min-width:1200px)');

  const role = localStorage.getItem('oga_user_role');
  if (role === 'oga-pharmacy-admin') return navigate('/orders-admin');

  const spacing = () => (isLarge ? 3 : 6);

  const handleFilterButtonClick = (event) => {
    if (event?.currentTarget === filterButtonEl) return;
    setFilterButtonEl(filterButtonEl ? null : event.currentTarget);
  };

  const handleStatus = (event, column) => {
    setStatus(column);
    handleFilterButtonClick(event);
    switch (column) {
      case 'Open Orders':
        return setFilter('ORDER_SENT');
      case 'Confirmed Orders':
        return setFilter('ORDER_RECEIVED');
      case 'Dispatched Orders':
        return setFilter('ORDER_DISPATCHED');
      case 'Closed Orders':
        return setFilter('ORDER_CLOSED');
      default:
        break;
    }
    return null;
  };

  const renderSearchField = () => (
    <SearchContainer item xs={9} lg={10}>
      <SearchTextField
        variant="outlined"
        label=""
        defaultValue={orderId}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder="Search Order"
        fullWidth
        className="mpFe-uat-orders-search-input"
        type="number"
        InputProps={{
          startAdornment:
  <InputAdornment
    position="start"
    style={{ cursor: 'pointer' }}
  >
    <SearchButton
      aria-label="Search Order"
      edge="start"
      disabled
    >
      <SearchImage
        alt="search icon"
        src={SearchIcon}
      />
    </SearchButton>
  </InputAdornment>
        }}
      />
    </SearchContainer>
  );

  const handleCloseMenu = () => {
    setCloseMenu(!closeMenu);
  };

  return (
    <MainContent>
      <MainContainer item container xs spacing={2}>
        <Hidden mdDown>
          <AffiliateMenu closeMenu={closeMenu} handleClose={handleCloseMenu} />
        </Hidden>
        <OrderGridContainer container item xs={12} md={closeMenu ? 10.8 : 9.3}>
          <TitleGrid />

          <StatisticsGridContainer ref={totalsContainer} container spacing={spacing()}>
            {STATISTICS.map((statistic) => (
              <StatisticsGrid key={statistic.id} item xs={12} md={3}>
                <StatisticCard
                  statistic={statistic}
                  statIcon={returnStatIcon}
                />
              </StatisticsGrid>
            ))}
          </StatisticsGridContainer>

          <ItemContainer container item>
            <OrderText>All Orders</OrderText>
            <Hidden mdDown>
              <SearchBox container>
                {renderSearchField()}
                <Grid item xs={3} lg={2}>
                  <CustomDateRanges
                    handleSubmit={handleSubmit}
                    xs={12}
                    styles={{
                      width: '100%', height: '3.5rem', border: '1px solid #E7E8E9', borderRadius: '7px',
                      marginLeft: '10px'
                    }}
                  />
                </Grid>
              </SearchBox>
            </Hidden>

            <ItemsBox container>
              {!verifiedBuyer ? (
                <EmptyStateGrid item style={{ textAlign: 'center' }}>
                  <EmptyState style={{ fontSize: '9.375rem' }} />
                  <NothingDisplay>
                    Nothing to display yet!
                  </NothingDisplay>
                  <NothingSubtitle>
                    You haven&apos;t placed any order yet!
                  </NothingSubtitle>
                </EmptyStateGrid>
              ) : (
                loading ? <Grid container><ProductsTableLoader /></Grid>
                  : (orders?.map((orderItem, index) => (
                    <ReturnRow
                      key={orderItem?.id}
                      orderItem={orderItem}
                      index={index}
                    />
                  )))
              )}

              {verifiedBuyer && (
                <FooterGrid item container>
                  <TablePagination
                    total={totalCount}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                  />
                </FooterGrid>
              )}
            </ItemsBox>
          </ItemContainer>
        </OrderGridContainer>
      </MainContainer>
      <FilterPopper
        filterButtonEl={filterButtonEl}
        status={status}
        handleStatus={handleStatus}
        handleFilterButtonClick={handleFilterButtonClick}
      />
    </MainContent>
  );
};

Order.propTypes = {
  orders: PropTypes.instanceOf(Array).isRequired,
  loading: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  handleSearch: PropTypes.func.isRequired,
  pageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  setPageCount: PropTypes.func.isRequired,
  setPageNumber: PropTypes.func.isRequired,
  setFilter: PropTypes.func.isRequired,
  orderId: PropTypes.string
};

Order.defaultProps = {
  loading: false,
  orderId: ''
};

export default Order;

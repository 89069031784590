import React, { useRef, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  DialogContent, Grid, Slide
} from '@mui/material';
import { Close } from '@mui/icons-material';
import PropTypes from 'prop-types';
import {
  DialogContentStyled, SelectNameTypo, TextFieldStyled, DialogContainer,
  TextLabel, CustomSelect, DescStyled, ButtonDarkStyled,
  CFormControl, MenuItems
} from './markupDialog.style';
import { ALL_CATEGORIES_QUERY } from '../../../queries/categories';
import { parseJson } from '../../affiliates/utils';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const MarkupDialog = ({
  openDialog, setOpenDialog, confirmDialogHandler, tab,
  location, category, setCategory, setCatId, setSellerMarkup, setCreditWallet, creditWallet
}) => {
  const selectRef = useRef();
  const [markup, setMarkup] = useState('');

  const { creditDays, creditLimit, gracePeriod } = creditWallet;

  const handleClose = () => {
    setMarkup('');
    setOpenDialog();
  };

  const handleConfirmProceed = () => {
    setSellerMarkup?.(markup);
    confirmDialogHandler();
    setMarkup('');
  };

  const { data } = useQuery(ALL_CATEGORIES_QUERY, {
    skip: tab === 'SELLER',
    fetchPolicy: 'no-cache'
  });

  const { allCustomerCategories = [] } = data || {};

  const handleCategoryChange = (e) => {
    const selectedName = e.target.value;
    const selectedCategory = allCustomerCategories.find((cat) => cat.name === selectedName);

    if (selectedCategory) {
      const { credit_days: cDays, credit_limit: cLimit, grace_period: gPeriod } = parseJson(selectedCategory?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
      setCreditWallet({ creditDays: cDays, creditLimit: cLimit, gracePeriod: gPeriod });
      setCategory({ name: selectedName, id: selectedCategory.id });
      setMarkup(selectedCategory.markup);
      setCatId(selectedCategory.id);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCreditWallet({ ...creditWallet, [name]: Number(value) });
  };

  const returnTextField = () => (
    <CFormControl
      fullWidth
      variant="filled"
      size="small"
      ref={selectRef}
    >
      <CustomSelect
        labelId="select-filled-label"
        id="select-filled"
        value={category?.name || ''}
        onChange={handleCategoryChange}
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        <MenuItems value="" disabled>
          Select Category
        </MenuItems>
        {allCustomerCategories?.map(({ name, id }) => (
          <MenuItems key={id} value={name}>
            <Grid item container justifyContent="space-between" alignItems="center">
              <SelectNameTypo>{name}</SelectNameTypo>
            </Grid>
          </MenuItems>
        ))}
      </CustomSelect>
    </CFormControl>
  );

  return (
    <DialogContainer
      open={openDialog}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogContentStyled>
        <Close
          fontSize="small"
          style={{
            position: 'absolute',
            right: '1.5rem',
            cursor: 'pointer',
            top: '1rem'
          }}
          onClick={handleClose}
        />
        <DialogContent style={{ marginTop: '2rem' }}>
          {tab === 'BUYER' ? (
            <DescStyled>
              Input Markup perentage
            </DescStyled>
          ) : (
            <DescStyled>
              Input percentage to be applied to orders placed
            </DescStyled>
          )}

          {tab === 'BUYER' && (
            <>
              <TextLabel>Location</TextLabel>
              <TextFieldStyled
                value={location}
                disabled
              />

              <TextLabel>Select Category</TextLabel>
              {returnTextField()}
            </>
          )}

          <TextLabel>Percentage</TextLabel>
          <TextFieldStyled
            value={markup}
            type="number"
            disabled={tab === 'BUYER'}
            onChange={(e) => {
              setMarkup(e.target.value);
            }}
            placeholder="%"
          />
          {tab === 'BUYER' && (
            <>
              <TextLabel>Credit Limit (₦) </TextLabel>
              <TextFieldStyled
                value={creditLimit}
                placeholder=" Enter credit limit for this category"
                onChange={handleChange}
                name="creditLimit"
                type="number"
                disabled
              />

              <TextLabel>Credit Days</TextLabel>
              <TextFieldStyled
                value={creditDays}
                placeholder="Enter the amount of days for repayment"
                onChange={handleChange}
                name="creditDays"
                type="number"
                disabled

              />
              <TextLabel>Grace Period</TextLabel>
              <TextFieldStyled
                value={gracePeriod}
                placeholder="Enter the amount of days"
                onChange={handleChange}
                name="gracePeriod"
                type="number"
                disabled
              />
            </>
          )}
          <ButtonDarkStyled disabled={!markup?.toString().length || (tab === 'BUYER' && !category?.name?.length)} onClick={handleConfirmProceed} fullWidth>
            Proceed to Verify Account
          </ButtonDarkStyled>
        </DialogContent>
      </DialogContentStyled>
    </DialogContainer>
  );
  // padding-bottom: ${({ therapeuticType }) => (therapeuticType ? '3rem' : '3rem')};
};

MarkupDialog.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  confirmDialogHandler: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  category: PropTypes.string,
  setCategory: PropTypes.func.isRequired,
  setCatId: PropTypes.func,
  setSellerMarkup: PropTypes.func,
  creditWallet: PropTypes.instanceOf(Object),
  setCreditWallet: PropTypes.func
};

MarkupDialog.defaultProps = {
  openDialog: false,
  setOpenDialog: () => false,
  category: '',
  setCatId: () => false,
  setSellerMarkup: () => false,
  creditWallet: {},
  setCreditWallet: () => false
};

export default MarkupDialog;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Button, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  WrapperGrid, PaymentDialogP, HeaderGrid, Header, FooterStyled
} from './paymentDialog.styles';
import SuccessDialog from '../../shared/successDialog';
import CustomInputBase from '../../customComponents/customInputBase';
import CustomSelectInputBase from '../../customComponents/customSelectInputBase';
import currencyFormatter from '../../shared/currencyFormatter';

const defaultDate = moment().format('YYYY-MM-DD');

const initialErrorState = {
  paymentTypeError: false,
  dateError: false,
  amountError: false,
  referenceError: false,
};

const PaymentDialog = ({
  open, onClose, refetch
}) => {
  const initialState = {
    paymentType: '',
    date: defaultDate,
    amount: '',
    reference: '',
  };
  const amountInvoice = 3000;


  const [state, setState] = useState(initialState);
  const [successOpenDialog, setSuccessOpenDialog] = useState(false);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [isEditing, setIsEditing] = useState(false);


  const {
    paymentTypeError,
    dateError,
    amountError,
    referenceError,
  } = errorState;


  const handleChangeType = (event) => {
    const { name, value } = event.target;
    setState({
      ...state,
      [name]: value
    });
  };

  const validateState = (name, value) => {
    switch (name) {
      case 'paymentType':
        return setErrorState({
          ...errorState,
          paymentTypeError: value === '' || !value.length || value === undefined
        });

      case 'date':
        return setErrorState({
          ...errorState,
          dateError: !value || value === undefined
        });

      case 'amount':
        return setErrorState({
          ...errorState,
          amountError: value === ''
        });

      default:
        return null;
    }
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    validateState(name, value);
    setState({ ...state, [name]: value });
  };

  const handleClose = () => {
    onClose();
    setState(initialState);
  };


  const paymentHandler = () => {
    const {
      amount, paymentType, date
    } = state;

    if (paymentType === '') {
      toast.error('Payment type is required');
    } else if (!date) {
      toast.error('Date is required');
    } else if (amount === '') {
      toast.error('Valid amount is required');
    } else {
      setSuccessOpenDialog(true);
      handleClose()
      console.log('state', state);
    }

    return setErrorState({
      ...errorState,
      paymentTypeError: paymentType === '',
      dateError: !date,
      amountError: amount === '',
    });
  };

  // const paymentTypeValue = [
  //   { name: 'Payment', value: 'PAYMENT' },
  //   { name: 'Rebate', value: 'REBATE' },
  //   { name: 'Return', value: 'RETURN' },
  //   { name: 'Tax Offset', value: 'TAX_OFFSET' },
  // ];

  const paymentTypeValue = [
    'Payment',
    'Rebate',
    'Return',
    'Tax Offset',
  ];

  const fields = [
    {
      name: 'paymentType', label: 'Payment Type', options: paymentTypeValue, secured: false, show: true,
      onChange: handleChangeType, placeholder: 'Select',
      error: paymentTypeError, helperText: 'Payment Type is required'
    },

    {
      name: 'date', label: 'Date', secured: false, placeholder: 'Payment Date', type: 'date', error: dateError, helperText: 'Date is required'
    },

    {
      name: 'amount', label: 'Amount', secured: false, placeholder: 'Enter amount', type: 'number', error: amountError, helperText: 'Amount is required',
      warmingMsg: state.amount > amountInvoice
        ? 'Invoice will be overpaid'
        : state.amount < amountInvoice
          ? `Amount due for this invoice will be ₦${currencyFormatter(Number(amountInvoice) - Number(state.amount))}`
          : 'Invoice will be fully paid',
    },

    {
      name: 'reference', label: 'Reference (optional)', secured: false, placeholder: 'Enter reference', type: 'text', error: referenceError, helperText: 'Reference',
    },
  ];

  const returnValue = (name, value) => {
    switch (name) {
      case 'amount':
        return value && `₦${currencyFormatter(value)}`;
      default:
        return value;
    }
  };


  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, secured, placeholder, onChange, val, type, readOnly, warmingMsg
    } = field;
    const value = state[fieldName];
    if ([
      'paymentType'
    ].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={val || value}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={required}
          style={{ color: '#464F5B', fontSize: '14px', fontWeight: '400' }}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={fieldName === 'amount' && !isEditing ? returnValue(fieldName, value) : value}
        size="small"
        type={fieldName === 'amount' && !isEditing ? 'text' : type || 'text'}
        error={error || false}
        helperText={error && helperText}
        warmingMsg={warmingMsg}
        required={required}
        style={{ color: '#464F5B', fontSize: '14px', fontWeight: '400' }}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={readOnly}
        onFocus={() => setIsEditing(true)}// Show raw number when editing
        onBlur={() => setIsEditing(false)}// Format when user leaves input
      />
    );
  };


  return (
    <>
      <PaymentDialogP
        open={open}
        data-testid="paymentDialog"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <WrapperGrid item container>
          <HeaderGrid>
            <Header>Add Payment</Header>
            <Close
              fontSize="small"
              onClick={handleClose}
              style={{ cursor: 'pointer', marginTop: '-10px' }}
            />
          </HeaderGrid>
          <Grid container spacing={2} mt={1}>
            {fields.map((field) => (
              <Grid item xs={12} key={field?.name}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
          <FooterStyled>
            <Button
              variant="outlined"
              onClick={handleClose}
              style={{ width: '100px', fontWeight: '700', fontSize: '14px' }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              data-testid="payment_button"
              onClick={paymentHandler}
              style={{ width: '100px', fontWeight: '700', fontSize: '14px' }}
            >
              Submit
            </Button>
          </FooterStyled>
        </WrapperGrid>
      </PaymentDialogP>
      <SuccessDialog
        openDialog={successOpenDialog}
        setOpenDialog={setSuccessOpenDialog}
        title="Payment Successful"
        desc="Your have successfully added made a payment of"
        option="ok"
        paymentType={true}
        amount={amountInvoice}
        refetch={refetch}
      />
    </>
  );
};

PaymentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  refetch: PropTypes.func,
};

PaymentDialog.defaultProps = {
  onClose: () => { },
  refetch: () => null,
};

export default PaymentDialog;

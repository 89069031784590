import { TextField } from '@mui/material';

import { styled } from '@mui/material/styles';

export const CTextField = styled(TextField)`
  margin-right: .6rem;
  font-size: .75rem;

  & .MuiOutlinedInput-inputMarginDense {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  & .MuiOutlinedInput-root {
    border-radius: ${({ straight }) => (straight ? '8px' : '17px')};
    border-radius: ${({ borderRadius }) => (borderRadius && '0.3rem')};
  }
  & .MuiInputBase-root {
    font-size: .8rem;
    padding: 6.5px 5px;
  }
  & .MuiFormLabel-root {
    font-size: .75rem;
    font-weight: 500;
    line-height: 1.45rem;
  }

  @media(max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
      height: 3.8rem;
      padding: 35px 20px 10px;
    }
    & .MuiOutlinedInput-root {
      border-radius: 35px;
    }
    & .MuiInputBase-root {
      font-size: 2.8rem;
      padding: 6.5px 5px;
    }
    & .MuiFormLabel-root {
      font-size: 1.75rem;
      font-weight: 500;
      line-height: 1.45rem;
    }
    & .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(20px, 12px) scale(1.5);
    }
  }
`;

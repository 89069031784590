import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import toast from 'react-hot-toast';
import PaymentDialog from './paymentDialog';
import { VERIFY_ACCOUNT_MUTATION } from '../../../mutations/verifyUser';
import {
  BodyCell, MainTableRow, ColorGrid, Dot, TitleHeader
} from './returnRow.styles';
import ActionPopper from './actionPopper';

const ReturnRow = ({
  row, index, refetch, tab
}) => {
  const [action, setAction] = useState(null);
  const [openMarkupDialog, setOpenMarkupDialog] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [markup, setMarkup] = useState('');
  const [category, setCategory] = useState({});
  const [openDialog, setOpenDialog] = useState(false);

  const navigate = useNavigate();
  const {
    id, dateCreated, location, businessuserSet, businessType
  } = row;

  const {
    name: businessName,
    buyerMeta: { verificationStatus: buyerStatus },
    sellerMeta: { verificationStatus: sellerStatus }
  } = businessuserSet[0]?.business || {};

  const status = tab === 'BUYER' ? buyerStatus : sellerStatus;

  const handleNavigate = () => {
    if (tab === 'BUYER') {
      return navigate(`/manage-account/buyer/${id}/details`);
    }
    return navigate(`/manage-account/seller/${id}/details`);
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const getColorGrid = (color, bkg, userStatus) => (
    <ColorGrid item container>
      <Dot background={bkg} />
      {userStatus}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (status) {
      case 'ACTIVE':
        return getColorGrid('#606060', '#00B588', 'Verified');
      case 'IN_ACTIVE':
        return getColorGrid('#606060', '#F29053', 'Unverified');
      case 'SUSPENDED':
        return getColorGrid('#606060', '#FF0F0F', 'Suspended');
      default:
        return getColorGrid('white', '#BF0505', 'N/A');
    }
  };

  const cells = [businessName, businessType.toLowerCase(), location.toLowerCase()];

  const returnCell = (val) => val || 'N/A';
  const renderBodyCells = () => cells.map((cell) => (
    <BodyCell
      key={cell}
      style={{ cursor: 'pointer', textTransform: 'capitalize' }}
      onClick={handleNavigate}
    >
      {returnCell(cell)}
    </BodyCell>
  ));

  const [verifyAccount, { loading }] = useMutation(VERIFY_ACCOUNT_MUTATION);

  const markupHandler = () => {
    setOpenMarkupDialog(!openMarkupDialog);
  };

  const handleAction = (event, type) => {
    if (type === 'verify') {
      markupHandler();
    }
    handleOptions(event);
  };

  return (
    <>
      <MainTableRow style={index % 2 ? { backgroundColor: ' #F3F9FF' } : { backgroundColor: '#FFF' }}>
        <BodyCell onClick={handleNavigate} />
        <BodyCell onClick={handleNavigate}>
          {index + 1}
        </BodyCell>
        <BodyCell onClick={handleNavigate}>
          {dayjs(dateCreated).format('DD/MM/YYYY (h:s)')}
        </BodyCell>
        {renderBodyCells()}
        <BodyCell onClick={handleNavigate}>
          {getStatus()}
        </BodyCell>
        <BodyCell>
          {
          loading ? 'loading...' : (
            <ActionPopper
              handleAction={handleAction}
              row={row}
              navigate={handleNavigate}
              status={status}
            />
          )
        }
        </BodyCell>
        <BodyCell>
          <p>Record Payment</p>
        </BodyCell>
        <BodyCell>
          {
          loading ? 'loading...' : (
            // <Button
            //   variant="none"
            //   onClick={() => setOpenModal(true)}
            //   style={{
            //     fontWeight: '700', fontSize: '14px', color: '#235A91'
            //   }}
            // >
            //   Record Payment
            // </Button>
            <TitleHeader
              onClick={() => setOpenModal(true)}
              style={{ cursor: 'pointer', color: '#235A91' }}
            >
              Record Payment
            </TitleHeader>
          )
        }
        </BodyCell>
      </MainTableRow>
      <PaymentDialog
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  index: PropTypes.instanceOf(Number),
  refetch: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired
};

ReturnRow.defaultProps = {
  row: {},
  index: 0,
};

export default ReturnRow;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import {
  MenuPaper,
  MenuText,
  MenuBox,
  SelectMenu,
  MenuTitles,
  MenuGrid,
  ItemGrid,
} from './affiliateMenu.styles';
import {
  OrdersLogoIcon,
  TrackLogoIcon,
  WalletLogo,
  ProfileLogoIcon,
  UploadLogoIcon,
} from '../../../assets/svgs';

const menuItems = [
  {
    label: 'Manage Orders', closedLabel: 'Orders', path: '/orders', key: 'orders', icon: <OrdersLogoIcon />
  },
  {
    label: 'Track Orders', closedLabel: 'Track', path: '/track-order', key: 'track', icon: <TrackLogoIcon />
  },
  {
    label: 'Credit Wallet', closedLabel: 'C.Wallet', path: '/credit-wallet', key: 'wallet', icon: <WalletLogo />
  },
  {
    label: 'Uploads', closedLabel: 'Uploads', path: '/uploads', key: 'uploads', icon: <UploadLogoIcon />
  },
  {
    label: 'Profile Settings', closedLabel: 'Profile', path: '/account-info', key: 'profile', icon: <ProfileLogoIcon />
  },
];

const AffiliateMenu = ({ closeMenu, handleClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [active, setActive] = useState('orders');

  useEffect(() => {
    const activeItem = menuItems.find((item) => item.path === pathname);
    setActive(activeItem ? activeItem.key : 'orders');
  }, [pathname]);

  return (
    <Grid item xs={12} md={closeMenu ? 1.2 : 2.7} lg={closeMenu ? 1.2 : 2.7}>
      <MenuPaper elevation={0} closed={closeMenu}>
        <MenuBox>
          <MenuText>Menus</MenuText>
          <SelectMenu onClick={handleClose} />
        </MenuBox>

        <Box mt={2}>
          {menuItems.map(({
            label, closedLabel, path, key, icon
          }) => (
            <MenuGrid
              key={key}
              container
              closed={closeMenu}
              onClick={path ? () => navigate(path) : undefined}
              active={active === key}
            >
              <ItemGrid item xs={closeMenu ? 12 : 1.5}>
                {React.cloneElement(icon, {
                  style: {
                    fontSize: '1.25rem',
                    marginTop: '3px',
                    marginBottom: closeMenu ? '.7rem' : '0',
                  },
                })}
                {closeMenu && <MenuTitles>{closedLabel}</MenuTitles>}
              </ItemGrid>
              {!closeMenu && (
                <Grid item xs={10.5}>
                  <MenuTitles active={active === key}>{label}</MenuTitles>
                </Grid>
              )}
            </MenuGrid>
          ))}
        </Box>
      </MenuPaper>
    </Grid>
  );
};

AffiliateMenu.propTypes = {
  closeMenu: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AffiliateMenu;

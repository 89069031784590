import { useQuery } from '@apollo/client';
import { Hidden, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AffiliatesImage from '../../assets/images/navigation/Affiliate_Placeholder.png';
import {
  BackOrderIcon, BatchDetails, BellIco, CartIco, Categories, CloseIconBlue, Dashboard,
  Inventory, MangeOrderIcon, MenuToggle, Orders, OrdersTruck, ProductType, Location,
  Returns, Sales, ScReport, Setting, Toggle, TrackOrderIcon, Users, UploadIcon,
  CategoriesIcon, AdminFeeIcon, PaymentIcon
} from '../../assets/svgs';
import AffiliateActionTypes from '../../providers/reducers/affiliate/affiliateTypes';
import NotificationsActionTypes from '../../providers/reducers/notifications/notificationsTypes';
import { useStateValue } from '../../providers/stateProvider';
import { ERP_NOTIFICATIONS } from '../../queries/notifications';
import { Subscriber } from '../../utils/subscriber';
import { getUser } from '../../utils/utils';
import InfoTillDialogue from '../categories/infoTillDialog';
import SimpleBadge from '../shared/simpleBadge';
import AffiliatesPopper from './affiliatesPopper';
import {
  AffiliateImage, AffiliatesLogo, BellButton, CloseMenuButton, DrawerContainer, NavButton,
  DropDownArrow, HeaderIcon, HeaderMenuButton, LogoIcon, NavLeftMenu, NavProfileIcon,
  NavRightMenu, NavbarContainer, ProfileTypo, SideAffiliateImage, SideLogoIcon, NavDivider,
  SideNavHeader, SideNavItems, SideProfileTypo, ViewCartTypo, OgaMpBlueLogo, OgaMpWhiteLogo,
  OgaAdminBlueLogo, OgaAdminWhiteLogo, DividerGrid
} from './navigationContainer.styles';
import NotificationsPopper from './notificationsPopper';
import SideNavItem from './sideNavItem';
import { GET_CART_TOTAL_COUNT } from '../../queries/cart';
import CartActionTypes from '../../providers/reducers/cart/cartTypes';
import { JSONParse } from '../../utils/json';

export const NavigationContainer = () => {
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [notificationsEl, setNotificationsEl] = useState(null);
  const [affiliatesLogoEl, setAffiliatesLogoEl] = useState(null);
  const [openTillDialogue, setOpenTillDialogue] = useState(false);
  const raspEnv = process.env.RASPBERRY_ENV;
  const navigate = useNavigate();

  const [
    {
      user,
      cart: { cartCounter },
      affiliate: { isBusinessVirtualStore },
      notifications: { count }
    },
    dispatch
  ] = Object.values(useStateValue());

  const {
    session, promos = {}, priceFactor, businessUserId, allowedPermissionsMap: { userPermissions },
    platform, businessLocation
  } = user;

  const {
    businessType, isSeller, isBuyer, isBuyerVerified, businessSet
  } = session || null;
  const loggedInUser = getUser(session);

  const viewRightButton = () => {
    switch (businessType) {
      case 'RETAILER':
      case 'WHOLESALER':
        return true;
      default:
        return false;
    }
  };

  const isSmallScreen = useMediaQuery('(max-width: 991px)');

  const location = useLocation();
  useEffect(() => {
    const usablePromos = [];
    const { availablePromos, affiliatePromos } = promos;

    const searchableAvailablePromos = `**${availablePromos?.join('**')}**`;
    affiliatePromos?.forEach((eachPromo) => {
      if (searchableAvailablePromos.indexOf(`**${eachPromo}**`) > -1) {
        usablePromos.push(eachPromo);
      }
    });

    dispatch({
      type: AffiliateActionTypes.UPDATE_AFFILIATE,
      payload: {
        affiliatePromos,
        availablePromos,
        usablePromos,
        pricePolicyFactor: priceFactor
      }
    });
  }, []);

  const { businessuserSet = [] } = session;
  const { meta } = businessuserSet.length && businessuserSet[0];
  const { logo, name: sellerBusinessName } = businessuserSet.length && businessuserSet[0].business;
  const affiliateLogo = logo || AffiliatesImage;
  const parsedMeta = JSONParse(meta) || {};
  const adminRole = parsedMeta?.role;

  const toggleSideNav = () => {
    setSideNavOpen(!sideNavOpen);
    dispatch({ type: 'openSideDrawer' });
  };

  const { location: sellerLocation } = businessSet?.length > 0 && businessSet[0];

  const sellerState = { name: sellerBusinessName, location: sellerLocation };

  const role = localStorage.getItem('oga_user_role');

  const handleSideClick = (route) => {
    toggleSideNav();
    setTimeout(() => {
      navigate(`/${route}`);
    }, 500);
  };

  const handleAffiliateLogoClick = (event) => {
    setAffiliatesLogoEl(affiliatesLogoEl ? null : event.currentTarget);
  };

  const handleBellClick = (event) => {
    setNotificationsEl(notificationsEl ? null : event.currentTarget);
  };

  const VIRTUAL_STORE_SIDE_ITEMS = [
    ['orders', Orders, 'orders'],
    ['orders-admin', Orders, 'order processing'],
    ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
  ];

  const STORE_MANAGER_SIDE_ITEMS_OFFLINE = [
    ['categories', Categories, 'Pricing categories', 'pricing_categories_view_pricing_categories'],
    ['affiliate-dashboard', Dashboard, 'affiliate dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    ['back-order', BackOrderIcon, 'back order'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['downloads', ScReport, 'Downloads'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
  ];
  const CHAIN_MANAGER_SIDE_ITEMS_OFFLINE = [
    ['affiliate-dashboard', Dashboard, 'affiliate dashboard', 'affiliate_dashboard_view_affiliate_dashboard'],
    ['return', Returns, 'Returns', 'returns_view_returns'],
    ['sc-dashboard', Dashboard, 'SC dashboard', 'sc_dashboard_view_sc_dashboard'],
    ['customers', Users, 'customers', 'customers_view_customers'],
    ['suppliers', OrdersTruck, 'suppliers/manufact..', 'suppliers_view_suppliers'],
    ['inventory', Inventory, 'inventory', 'inventory_view_inventory'],
    ['back-order', BackOrderIcon, 'back order'],
    ['sales', Sales, 'sales', 'sales_view_sales'],
    // ['batch-details', BatchDetails, 'batch details', 'batch_details_view_batch_details'],
    ['stock-count-report', ScReport, 'stock count report', 'stock_count_report_view_stock_count_report'],
    // ['therapeutic-types', ProductType, 'product types', 'product_type_view_product_type'],
    ['roles', Setting, 'Roles', 'role_permissions_view_role_permissions'],
    ['cm-dashboard', Dashboard, 'CM dashboard', 'cm_dashboard_view_cm_dashboard'],
    ['downloads', ScReport, 'Downloads']
  ];

  const PURE_MP_ADMIN_SIDE_ITEMS = [
    ['manage-accounts', Users, 'Manage Accounts'],
    ['orders-admin', MangeOrderIcon, 'Manage Orders'],
    ...(adminRole === 'BUYER' ? [['customer-categories', CategoriesIcon, 'Categories']] : []),
    ['admin-settings-dashboard', AdminFeeIcon, 'Admin'],
    ['order-payment', PaymentIcon, 'Payment'],
  ];

  const SELLER_SIDE_ITEMS = [
    ['dashboard', Dashboard, 'dashboard'],
    ['manage-orders', MangeOrderIcon, 'Manage Orders'],
    ['inventory', Inventory, 'Inventory'],
    ['uploads', UploadIcon, 'Uploads']
  ];

  const BUYER_SIDE_ITEMS = [
    ['orders', MangeOrderIcon, 'Manage Orders'],
    ['track-order', TrackOrderIcon, 'Track Order'],
    ['uploads', UploadIcon, 'Uploads']
  ];

  const WHOLESALER_SELLER_SIDE_ITEMS = [
    ['dashboard', Dashboard, 'dashboard'],
    ['manage-orders', MangeOrderIcon, 'Manage Orders'],
    ['track-order', TrackOrderIcon, 'Track Order'],
    ['inventory', Inventory, 'Inventory'],
    ['uploads', UploadIcon, 'Uploads']
  ];

  const sideItems = () => {
    if (isBusinessVirtualStore) {
      return VIRTUAL_STORE_SIDE_ITEMS;
    }
    if (raspEnv && role === 'store-manager') {
      return STORE_MANAGER_SIDE_ITEMS_OFFLINE;
    }
    if (raspEnv && role === 'chain-manager') {
      return CHAIN_MANAGER_SIDE_ITEMS_OFFLINE;
    }
    switch (role) {
      case 'ADMIN':
        return PURE_MP_ADMIN_SIDE_ITEMS;
      case 'RETAILER':
        return BUYER_SIDE_ITEMS;
      case 'WHOLESALER': {
        if (isSeller) return WHOLESALER_SELLER_SIDE_ITEMS;
        return BUYER_SIDE_ITEMS;
      }
      default:
        return SELLER_SIDE_ITEMS;
    }
  };

  const { data } = useQuery(GET_CART_TOTAL_COUNT, {
    fetchPolicy: 'cache-and-network',
  });
  const { cartItemsTotalNumber = 0 } = data || {};

  useEffect(() => {
    dispatch({
      type: CartActionTypes.CART_COUNT,
      payload: cartItemsTotalNumber
    });
  }, [cartItemsTotalNumber]);

  const { data: notificationsData, refetch } = useQuery(ERP_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      pageCount: 10,
      pageNumber: 1,
    }
  });
  const { erpNotifications, erpNotificationsTotalNumber } = notificationsData ?? [];
  useEffect(() => {
    Subscriber.report('NotificationCount', erpNotificationsTotalNumber);
    dispatch({
      type: NotificationsActionTypes.UPDATE_NOTIFICATION_COUNT,
      payload: {
        count: erpNotificationsTotalNumber,
      }
    });
  }, [erpNotifications, erpNotificationsTotalNumber]);

  const handleWholesalerBtn = () => {
    if (!isSeller) navigate('/register', { state: { becomeSeller: true, sellerState } });
    else navigate('/new-order');
  };
  const handleDisableButton = () => (isBuyer && !isSeller && !isBuyerVerified);

  return (
    <>
      <NavbarContainer theme={useTheme()} open={sideNavOpen}>
        <NavLeftMenu item container md={4} xs={8}>
          <HeaderMenuButton className="navigation-hamburger" onClick={toggleSideNav} disabled={handleDisableButton()}>
            <Toggle data-testid="toggleIcon" style={{ fontSize: isSmallScreen ? '5rem' : '2.2rem' }} />
          </HeaderMenuButton>
          {businessType === 'ADMIN' ? <OgaAdminBlueLogo /> : <OgaMpBlueLogo /> }
        </NavLeftMenu>
        <NavRightMenu item container md={8} xs={platform === 'erp' ? 11 : 6}>
          <AffiliatesLogo item container>
            {!isSmallScreen ? (
              <HeaderIcon disabled>
                <Location style={{ fontSize: isSmallScreen ? '2rem' : '1.5rem', fill: 'none' }} />
                <ViewCartTypo>{businessLocation?.toLowerCase()}</ViewCartTypo>
              </HeaderIcon>
            ) : ''}
            {isBuyer ? (
              <HeaderIcon
                onClick={() => navigate('/cart', { state: { prevLocation: location.pathname } })}
                className="navigation-view-cart"
              >
                <SimpleBadge value={cartCounter}>
                  <CartIco style={{ fontSize: isSmallScreen ? '5rem' : '2.05rem' }} />
                </SimpleBadge>
                <Hidden mdDown>
                  <ViewCartTypo>View Cart</ViewCartTypo>
                </Hidden>
              </HeaderIcon>
            ) : ''}
            <Hidden mdDown>
              <BellButton data-testid="notificationModal" onClick={handleBellClick}>
                <SimpleBadge value={count}>
                  <BellIco style={{ fontSize: isSmallScreen ? '5rem' : '2.05rem' }} />
                </SimpleBadge>
              </BellButton>
            </Hidden>

            <LogoIcon data-testid="affiliatesOptions" onClick={handleAffiliateLogoClick}>
              <AffiliateImage alt="affiliates logo" src={affiliateLogo} />
              <Hidden mdDown>
                <ProfileTypo>{loggedInUser}</ProfileTypo>
                <DropDownArrow />
              </Hidden>
            </LogoIcon>
          </AffiliatesLogo>
          {viewRightButton() && businessType !== 'RETAILER' ? (
            <DividerGrid item container>
              <NavDivider orientation="vertical" variant="middle" flexItem />
              <NavButton
                variant="contained"
                onClick={handleWholesalerBtn}
              >
                <NavProfileIcon />
                {isSeller ? 'Buy on marketplace' : 'Become a Seller'}
              </NavButton>
            </DividerGrid>
          ) : ''}
        </NavRightMenu>
      </NavbarContainer>

      <DrawerContainer variant="persistent" anchor="left" open={sideNavOpen}>
        <SideNavHeader item container>
          <Hidden mdDown>
            <HeaderMenuButton className="navigation-hamburger" onClick={toggleSideNav}>
              <MenuToggle data-testid="menuToggle" style={{ fontSize: '2.2rem' }} />
            </HeaderMenuButton>
            {businessType === 'ADMIN' ? <OgaAdminWhiteLogo /> : <OgaMpWhiteLogo />}
          </Hidden>
          <Hidden mdUp>
            <CloseMenuButton container>
              <CloseIconBlue onClick={toggleSideNav} style={{ fontSize: '4rem', marginLeft: 'auto' }} />
            </CloseMenuButton>

            <SideLogoIcon container>
              <SideAffiliateImage alt="affiliates logo" src={affiliateLogo} />
              <SideProfileTypo>{loggedInUser}</SideProfileTypo>
            </SideLogoIcon>
          </Hidden>
        </SideNavHeader>
        <SideNavItems>
          {sideItems().map((sideItem) => (
            sideItem.length < 4 ? (
              <SideNavItem key={sideItem} sideItem={sideItem} handleSideClick={handleSideClick} />
            ) : userPermissions?.includes(sideItem[3]) && (
              <SideNavItem key={sideItem} sideItem={sideItem} handleSideClick={handleSideClick} />
            )
          ))}
        </SideNavItems>
      </DrawerContainer>

      <AffiliatesPopper
        affiliatesLogoEl={affiliatesLogoEl}
        handleAffiliateLogoClick={handleAffiliateLogoClick}
        businessLocation={businessLocation}
        isSmallScreen={isSmallScreen}
      />
      <NotificationsPopper
        notificationsEl={notificationsEl}
        handleBellClick={handleBellClick}
        businessUserId={businessUserId}
        notifications={erpNotifications}
        refetch={refetch}
      />
      <InfoTillDialogue
        dialogOpen={openTillDialogue}
        closeDialog={() => setOpenTillDialogue(false)}
      />
    </>
  );
};

export default NavigationContainer;

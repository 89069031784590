import {
  Grid, Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const PaymentGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 1.8rem 2rem;
  background: #F3F9FF;
  @media(max-width: 991px) {
    padding: 0px 35px;
  }
`;

export const TitleGridContainer = styled(Grid)`
  margin: 2rem 1rem 2rem 0;
  align-items: center;
`;

export const TitleTextGridContainer = styled(Grid)`
`;

export const TitleHeader = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 700;
  color: #303030;
  margin-bottom: .5rem;
`;

export const Description = styled(Typography)`
  font-size: 14px;
  color: #303030;
`;

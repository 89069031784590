import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import MainContent from '../customComponents/mainContent';
import CustomButton from '../customComponents/customButton';
import {
  PaymentGridContainer,
  TitleHeader,
  Description,
  TitleGridContainer,
  TitleTextGridContainer
} from './paymentContainer.styles'
import Payments from './payments/payments'
import { useStateValue } from '../../providers/stateProvider';
import { RefreshIcon } from '../../assets/svgs';
import BulkPaymentDialog from './payments/createBulkPayment';

const PaymentContainer = () => {
  const [, dispatch] = Object.values(useStateValue());
  const [openModal, setOpenModal] = React.useState(false);

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: false });
  }, [dispatch]);

  return (
    <MainContent>
      <PaymentGridContainer container>
        <TitleGridContainer container item xs={12} justifyContent="space-between">
          <TitleTextGridContainer item md={6}>
            <TitleHeader>Payment</TitleHeader>
            <Description>View all of your payments here</Description>
          </TitleTextGridContainer>
          <Grid container md={6} spacing={2} justifyContent="flex-end">
            <CustomButton
              style={{
                width: '9rem', height: '2.95rem', fontWeight: '700', fontSize: '0.75rem',
                borderRadius: '0.3rem', gap: '1rem'
              }}
            // onClick={handleSetFilter}
              onClick={() => setOpenModal(true)}
            >
              <RefreshIcon style={{ fill: 'none' }} />
              Upload
            </CustomButton>
          </Grid>
        </TitleGridContainer>
        <Grid item container xs={12} mt={3}>
          <Payments />
        </Grid>
      </PaymentGridContainer>
      <BulkPaymentDialog
        openProdDialog={openModal}
        toggleHandler={() => setOpenModal(false)}
      />
    </MainContent>
  );
};

export default PaymentContainer;

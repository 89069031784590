import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import {
  BodyCell, MainTableRow,
} from './returnRow.styles';
import { Dots } from '../../../assets/svgs';
import ActionPopper from './actionPopper';
import ConfirmDialog from './confirmDialog';
import SuccessDialog from '../indevidualCategory/successDialog';
import CreateCategoryDialog from './createCategoryDialog/createCategoryDialog';
import { DELETE_CUSTOMER_CATEGORY } from '../../../mutations/categories';
import ViewBuyersDialog from '../viewBuyers/viewBuyersDialog';
import CreateCreditLimiDialog from './createCreditLimiDialog/createCreditLimiDialog';
import { parseJson } from '../../affiliates/utils';

const ReturnRow = ({ row, refetch }) => {
  const [action, setAction] = useState(null);
  const [open, setOpen] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [createCategoryDialog, setCreateCategoryDialog] = useState(false);
  const [openViewBuyers, setOpenViewBuyers] = useState(false);
  const [deleteCustomerCategory] = useMutation(DELETE_CUSTOMER_CATEGORY);
  const [openModal, setOpenModal] = useState(false);

  const {
    id, name, markup, businessCount, creditWallet
  } = row;

  const { credit_days: creditDays, credit_limit: creditLimit, grace_period: gracePeriod } = parseJson(creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));

  const cells = [markup, creditLimit?.toLocaleString('en-US'), creditDays, gracePeriod, businessCount];

  const returnCell = (value) => value || '-';

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const handleCloseModal = () => {
    setAction(false);
  };

  const toggleConfirmation = () => {
    setOpen(!open);
  };
  const toggleSuccessModal = () => {
    setSuccessModal(!successModal);
  };

  const handleDelete = () => {
    toggleConfirmation();
    if (businessCount) return toast.error('Can not delete category assigned to buyers');
    const variables = {
      categoryId: +id
    };
    deleteCustomerCategory({ variables })
      .then(({ data }) => {
        const { message } = data?.deleteBusinessCategory || {};
        toast.success(message);
        setSuccessModal(true);
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      });
  };

  const handleDialog = () => {
    setCreateCategoryDialog(!createCategoryDialog);
  };

  const handleAction = (event, type) => {
    if (type === 'edit') {
      setCreateCategoryDialog(true);
    } else if (type === 'delete') {
      toggleConfirmation();
    } else if (type === 'addCreditLimit') {
      setOpenModal(true);
    } else {
      setOpenViewBuyers(true);
    }
    handleOptions(event);
  };
  const renderBodyCells = () => cells.map((cell, index) => (
    <BodyCell key={cell} className={`mpAdmin-uat-customerCategory-cell-${index}`}>{returnCell(cell)}</BodyCell>
  ));

  return (
    <>
      <MainTableRow item container key={id}>
        <BodyCell>
          #
          {id}
        </BodyCell>
        <BodyCell>{name}</BodyCell>
        {renderBodyCells()}
        <BodyCell className="mpAdmin-uat-customerCategory-action-button">
          <Dots fontSize="large" onClick={handleOptions} style={{ cursor: 'pointer' }} />
        </BodyCell>
        <ActionPopper
          action={action}
          handleAction={handleAction}
          row={row}
          handleClose={handleCloseModal}
        />
      </MainTableRow>
      <CreateCategoryDialog
        open={createCategoryDialog}
        close={handleDialog}
        isEdit="true"
        category={row}
        refetch={refetch}
      />
      <ConfirmDialog
        openConfirmation={open}
        handleClose={toggleConfirmation}
        title="Remove Category"
        discreption="Are you sure you want to remove this category?"
        buttonTitle="Yes, Remove"
        handleConfirm={handleDelete}
      />
      <SuccessDialog
        openConfirmation={successModal}
        handleClose={toggleSuccessModal}
        title="Category Removed Successfully!"
        discreption="Your category has been successfully removed!"
      />
      <ViewBuyersDialog
        open={openViewBuyers}
        close={() => setOpenViewBuyers(false)}
        category={row}
        refetch={refetch}
      />
      <CreateCreditLimiDialog
        open={openModal}
        close={() => setOpenModal(false)}
        category={row}
        refetch={refetch}
      />
    </>
  );
};

ReturnRow.propTypes = {
  row: PropTypes.instanceOf(Object),
  refetch: PropTypes.func.isRequired
};

ReturnRow.defaultProps = {
  row: {},
};

export default ReturnRow;

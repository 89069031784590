import { styled } from '@mui/material/styles';
import { Popper, Paper, Box } from '@mui/material';

export const PopperContainer = styled(Popper)`
  z-index: 15000000;
  min-height: 0rem;
  min-width: 10rem;
  margin-left: ${({ marginLeft }) => (marginLeft)};
  margin-right: ${({ marginRight }) => (marginRight)};
  margin-top: ${({ marginTop }) => (marginTop)};
  width: ${({ width }) => (width)};
`;

export const WrapperPaper = styled(Paper)`
  z-index: 1500;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.24);
  border-radius: ${({ creditWallet }) => (creditWallet ? '0.25rem' : '.5rem')};
  padding: ${({ creditWallet }) => (creditWallet ? '10px 5px' : '1rem 0')}
  margin-top: ${({ productInfoPopper }) => (productInfoPopper && '6rem')};
  margin-left: ${({ creditWallet }) => (creditWallet && '5rem !important')};
  width: ${({ creditWallet }) => (creditWallet && '5rem !important')};

  @media(max-width: 991px) {
    border-radius: 15px;
  }
`;

export const Arrow = styled(Box)`
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  position: absolute;
  border-bottom: 15px solid white;
  top: -12px;
  right: 18px;
`;

import {
  Grid, Typography, IconButton, Paper,
  Button, TextField, TableHead, TableCell, Box
} from '@mui/material';

import { styled } from '@mui/material/styles';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const MainContainer = styled(Grid)`
  background: #f7fafe;
  padding: 30px 35px;

`;

export const OrderGridContainer = styled(Grid)`
  flex-direction: column;
    padding-top: 30px;


  @media(max-width: 991px) {
    padding-top: 40px;
  }
`;

export const StatisticsGridContainer = styled(Grid)`
  margin-top: 1rem;
  margin-bottom: 2rem;
  align-items: center;

  @media(min-width: 992px) {
    margin-top: 15px;
    margin-bottom: 2rem;
  }
`;

export const StatisticsGrid = styled(Grid)`

`;

export const OrdersTab = styled(Grid)`
  justify-content: space-between;
  border-top: 2px solid #CCCCCC;
  border-bottom: 2px solid #CCCCCC;

  @media(min-width: 992px) {
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
  }
`;

export const ActiveOrderNumber = styled(Typography)`
  font-size: 30px;
  color: #424242;
  font-weight: 600;
  padding: 35px 0px;

  @media(min-width: 992px) {
    font-size: 20px;
    padding: 30px 0px;
  }
`;

export const FooterGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  margin: 30px 0;
  background: #ffffff;

  @media(max-width: 992px) {
    margin-bottom: 7rem;
  width: 100%;
  place-items: center;
  }
`;

export const OrderItemsGrid = styled(Grid)`
  margin-bottom: 30px;

  @media(max-width: ${sm}) {
    margin-bottom: 10px;
  }
`;

export const OrderCountText = styled(Typography)`
  font-size: 22px;
  color: #4D4F5C;
  margin: 14px 0;

  @media(min-width: 992px) {
    font-size: 18px;
  }
`;

export const IcoButton = styled(IconButton)`
  color: #424242;
  margin-left: 3rem;
  padding: 12px;
`;

export const TabImage = styled('img')`
  width: 40px;

  @media(min-width: 992px) {
    width: 26px;
  }
`;

export const FilterContainer = styled(Paper)`
  width: 100%;
`;

export const HeaderGrid = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
`;

export const HeaderTypo = styled(Typography)`
  font-size: 2rem;
  font-weight: 600;
  padding: 10px 25px;
  line-height: 4.5rem;

  @media(min-width: 992px) {
    font-size: 1rem;
    line-height: 2.5rem;
    padding: 10px;
  }
`;

export const FilterButton = styled(Button)`
  font-size: 1.5rem;
  height: 2.8rem;
  background: #424242;
  color: #fff;
  margin-right: 25px;

  &.MuiButton-text:hover {
    background-color: #424242;
  };

  @media(min-width: 992px) {
    font-size: 0.875rem;
    height: 1.8rem;
    margin-right: 10px;
  }
`;

export const BodyGrid = styled(Grid)`
  padding: 1.5rem;

  @media(min-width: 992px) {
    padding: 1rem;
  }
`;

export const CTextField = styled(TextField)`
  margin-bottom: 1.5rem;

  &:nth-child(3), :nth-child(5) {
    margin-bottom: .5rem;
  }

  @media(max-width: 991px) {
    & .MuiFilledInput-input {
      font-size: 2rem;
      height: 3.8rem;
      padding: 35px 20px 10px;
    }
    & .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(20px, 12px) scale(1.5);
    }
  }
`;

export const MainTableHead = styled(TableHead)`

`;

export const HeaderCell = styled(TableCell)`
  font-size: 1rem;
  font-weight: 600;
  line-height: 2.5rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  padding: 10px;

  @media(max-width: 991px) {
    font-size: 1.4rem;
  }

  /* :last-child {
    width: 1%;
  }
  &:nth-child(1), :nth-last-child(2) {
    width: 3%;
    padding: 0;
  }
  &:nth-child(2) {
    width: 56%;
  }

  @media(min-width: 992px) {
    &:nth-child(2) {
      width: 24%;
    }
    &:nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7) {
      width: 12%;
    }
    &:nth-child(8) {
      width: 9%;
    }
  } */
`;

export const NoDataTypo = styled(Typography)`
  font-size: 2rem;
  padding: 15px;
  line-height: 3.5rem;

  @media(min-width: 992px) {
    font-size: 1rem;
    padding: 10px;
    line-height: 2.5rem;
  }
`;

export const OrderText = styled(Typography)`
  color: #303030;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
`;

export const SearchContainer = styled(Grid)`
  align-items: center;
  margin-bottom: 2rem;
  border: 1px solid #E7E8E9;
  border-radius: 8px;

  & .MuiAutocomplete-popper {
    z-index: 1400;
  };

  @media(min-width: 992px) {
    margin-bottom: 0;
  }
`;

export const SearchTextField = styled(TextField)`
  background: #FFFFFF;
  border-radius: 8px;
  border: 1px solid rgba(231, 232, 233, 1);

  & ::placeholder {
    font-size: .813rem;
    font-weight: 500;
    color: #858383;
  }


  & .MuiOutlinedInput-input {
    padding: 25px;
    @media(max-width: 991px) {
      padding: 30px;
    }
  }

  & .MuiOutlinedInput-root {
    font-size: 2.2rem;
    border-radius: 10px;
  }

  & .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  }

  & .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
  }

  & .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: #FFFFFF;
    border-width: 0;
  }

  @media(min-width: 992px) {
    border: none;

    & .MuiOutlinedInput-input {
      padding: 15px 14px;
    }

    & .MuiOutlinedInput-root {
      font-size: 1rem;
      border-radius: 8px;
      min-height: 2.5rem;
    }

  }
`;

export const SearchButton = styled(IconButton)`
  cursor: pointer;
  padding: 14px 20px;
  color: #424242;
  border-radius: 10px;
  z-index: 2;
  margin-right: -14px;

  &:hover {
    background-color: #D2C647;
  }

  &:disabled {
    color: #424242;
  }

  @media(min-width: 992px) {
    padding: 10px 7px 10px 12px;
  }
`;

export const SearchImage = styled('img')`
  width: 64px;

  @media(min-width: 992px) {
    width: 1.25rem;
  }
`;

export const SearchBox = styled(Grid)`
  // justify-content: space-between;
`;

export const FilterDropButton = styled(Button)`
  @media (max-width: 991px) {
    margin-left: 30px;
    width: 150px;
    height: 100px;
  }
  @media (min-width: 992px) {
    margin-left: 5px;
    height: 50px;
  }
`;

export const FilterDropGrid = styled(Grid)`
  text-align: right;
`;

export const FilterImg = styled('img')`
padding-right: 6px;
@media(max-width: 991px) {
  height: 3rem;
}
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
`;

export const GridContainer = styled(Grid)`
    padding: 15px 25px;
    width: 20rem;
    align-items: center;
    cursor: pointer;

    @media(min-width: 992px) {
      padding: 0px;
      width: 200px;
    }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 1.7rem;

  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1rem;
    padding: 6px 12px;
  }
`;

export const ItemsBox = styled(Grid)`
  margin-top: 20px;

  @media(max-width: 991px) {
    background-color: #fff;
    padding: 50px 30px;
    border-radius: 29px;
  }
`;

export const ScrollBox = styled(Box)`
  margin-bottom: 20px;
`;

export const PaginationBox = styled(Box)`
  @media (min-width: 992px) {
    margin-top: -45px;
  }
`;

export const ItemContainer = styled(Grid)`
  background: #FFFFFF;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0px 11px 18px 0px rgba(0, 0, 0, 0.04);
`;

export const EmptyStateGrid = styled(Grid)`
  width: 100%;
  text-align: center;
  margin: 3rem 0;
`;

export const NothingDisplay = styled(Typography)`
  font-weight: 700;
  font-size: 1.125rem;
  color: #303030;
  margin-bottom: 1rem;
`;

export const NothingSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: .875rem;
  color: #606060;
`;

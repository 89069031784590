import {
  TableCell, Grid,
  TableRow,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainTableRow = styled(TableRow)`

  & > * {
    border-bottom: unset;
  }
  :nth-child(2n) {
    background: #F3F9FF;
  }
`;

export const BodyCell = styled(TableCell)`
  text-transform: capitalize;
  font-size: 1.2rem;
  line-height: 26px;
  padding: 10px;
  color: #606060;
  white-space: nowrap;
  overflow: hidden;
  border: 0;

  @media(min-width: 992px) {
    font-size: 1rem;

   &:nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4), :nth-child(5), :nth-child(6), :nth-child(7), :nth-child(8) {
      min-width: 12.5%;
    }
  }
`;

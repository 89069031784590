import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { KeyboardArrowDown } from '@mui/icons-material';
import toast from 'react-hot-toast';
import {
  BusinesssName, HeaderBox, VerifyBox, Bullet, VerifiedText,
  AdornmentStyled, CircularProgressLoader, EditButton, EditButtonText,
  TitleBox, BoxGrid, BoxTitleText, CategoryBox, CategoryName,
  CreditContainer,
  CreditGrid,
  TextWrapper,
  CreditText,
  CreditValue,
  Reset
} from './buyerProfile.styles';
import {
  ProfileCategory, Editicon, SaveUpdateIcon, SyncIcon
} from '../../../../assets/svgs';
import { CustomInputBase, CustomSelectInputBase } from '../../../shared/customInput';
import { UPDATE_BUSINESS_PROFILE } from '../../../../mutations/business';
import { UPDATE_CUSTOMER_CATEGORY } from '../../../../mutations/categories';
import { JSONParse } from '../../../../utils/json';
import SuccessDialog from '../../../shared/successDialog';
import { validateEmailAddress } from '../../../../utils/funcs';
import BuyersOrder from './ordersPage';
import BuyerMarkup from './buyerMarkup';
import ConfirmDialog from '../../../shared/confirmDialog';
import { parseJson } from '../../../affiliates/utils';

const BuyerProfile = ({
  userData, businessId, accountType, tab, changeTab, category
}) => {
  const initialState = {
    fullname: '',
    emailAddress: '',
    phoneNumber: '',
    deliveryCity: '',
    deliveryAddress: '',
    landmark: '',
    gender: '',
    businessType: '',
    businessName: '',
    contactPersonName: '',
    contactPersonPhoneNumber: '',
    salesRep: '',
    deliveryState: '',
    creditDays: '',
    creditLimit: '',
    gracePeriod: '',
  };

  const initialErrorState = {
    fullnameError: false,
    emailAddressError: false,
    phoneNumberError: false,
    deliveryCityError: false,
    deliveryAddressError: false,
    landmarkError: false,
    genderError: false,
    businessTypeError: false,
    businessNameError: false,
    contactPersonPhoneNumberError: false,
    salesRepError: false,
    deliveryStateError: false,
    contactPersonNameError: false,
  };

  const [state, setState] = useState(initialState);
  const [errorState, setErrorState] = useState(initialErrorState);
  const [editing, setEditing] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [updateBusinessInfo] = useMutation(UPDATE_BUSINESS_PROFILE);
  const [updateBusiness] = useMutation(UPDATE_CUSTOMER_CATEGORY);
  const [loading, setLoading] = useState(false);
  const [phoneNumHelperText, setPhoneNumHelperText] = useState('Phone number required');
  const [emailHelperText, setEmailHelperText] = useState('Email Adress required');
  const [markupDialog, setMarkupDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [markup, setMarkup] = useState('');

  const {
    markup: catMarkup, name: catName, id: catId
  } = category || {};

  const validPhoneNumber = (str) => (str.length < 9 || str.length > 13);

  useEffect(() => {
    if (catMarkup) {
      setMarkup(catMarkup);
    }
  }, [category, catMarkup]);

  const phoneAdornment = (
    <AdornmentStyled>
      <span>NG</span>
      <KeyboardArrowDown />
    </AdornmentStyled>
  );

  const {
    fullname, emailAddress, phoneNumber, deliveryCity,
    deliveryAddress, landmark, gender, businessType, contactPersonName,
    businessName, contactPersonPhoneNumber, salesRep, creditDays, creditLimit, gracePeriod
  } = state;

  const {
    fullnameError, emailAddressError, phoneNumberError, deliveryCityError,
    deliveryAddressError, landmarkError, businessNameError, salesRepError,
    contactPersonNameError
  } = errorState;

  const {
    name, location, buyerContact, fullname: defaultFullName,
    settings, createdBy, contact, businessType: defaultBusinessType, creditWallet
  } = userData?.business || {};

  const {
    city, contactPersonName: defaultContactName, deliveryAddress: defaultDeliveryAddress,
    emailAddress: defaultEmail, landmark: defaultLandmark, phoneNumber: defaultPhoneNumber,
    salesRepresentativeName: defaultSalesRep
  } = buyerContact || {};

  const { meta } = createdBy || {};
  const parseMeta = JSONParse(meta);
  const parseContact = JSONParse(contact);
  const parseSettings = JSONParse(settings);
  const { gender: userGender } = parseMeta;
  const { buyer_contact: buyerCon } = parseContact;
  const { contact_person_phone_number: contactPersonNumber } = buyerCon;
  const { kyc } = parseSettings;
  const { business_type: busType } = kyc || {};

  useEffect(() => {
    const { credit_days: cDays, credit_limit: cLimit, grace_period: gPeriod } = parseJson(creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
    const { credit_days: catCDays, credit_limit: catCLimit, grace_period: catGPeriod } = parseJson(category?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));

    setState({
      ...state, businessName: name, fullname: defaultFullName, phoneNumber: defaultPhoneNumber, businessType: busType ?? defaultBusinessType, contactPersonName: defaultContactName,
      gender: userGender, landmark: defaultLandmark, salesRep: defaultSalesRep, deliveryCity: city,
      contactPersonPhoneNumber: contactPersonNumber, emailAddress: defaultEmail, deliveryAddress: defaultDeliveryAddress, creditDays: cDays ?? catCDays, creditLimit: cLimit ?? catCLimit, gracePeriod: gPeriod ?? catGPeriod
    });
  }, [userData]);

  const handleEditInfo = () => {
    setLoading(true);
    const cWallet = { creditDays: Number(creditDays), creditLimit: Number(creditLimit), gracePeriod: Number(gracePeriod) };

    const updatedInfo = {
      businessName, userFullName: fullname, userPhoneNumber: phoneNumber, businessType,
      deliveryEmail: emailAddress, landmark, gender, salesRepresentativeName: salesRep, city: deliveryCity,
      contactPersonName, contactPersonPhoneNumber, deliveryAddress, creditWallet: cWallet
    };

    updateBusinessInfo({
      variables: {
        businessId, updatedInfo, accountType
      }
    })
      .then(() => {
        setOpenDialog(true);
      })
      .catch((err) => {
        toast.error(err?.message);
      })
      .finally(() => {
        setEditing(false);
        setLoading(false);
      });
  };

  const handleUpdateMarkup = () => {
    updateBusiness({
      variables: {
        categoryId: Number(catId),
        markup: Number(markup),
        name: catName
      }
    })
      .then(() => {
        setSuccessDialog(true);
        setConfirmDialog(false);
        setMarkupDialog(false);
      })
      .catch((err) => {
        toast.error(err?.message);
      });
  };

  const handleSaveButtonClick = () => {
    if (!editing) return setEditing(true);

    if (businessName === '') {
      toast.error('Business Name is required');
    } else if (salesRep === '') {
      toast.error('Sales Representative is required');
    } else if (fullname === '') {
      toast.error('Full Name is required');
    } else if (phoneNumber === '') {
      toast.error('Phone Number is required');
    } else if (landmark === '') {
      toast.error('Landmark is required');
    } else if (deliveryCity === '') {
      toast.error('Delivery City is required');
    } else if (businessType === '') {
      toast.error('Business Type is required');
    } else if (deliveryAddress === '') {
      toast.error('Delivery Address is required');
    } else if (emailAddress === '') {
      toast.error('Email Address is required');
    } else if (!validateEmailAddress(emailAddress)) {
      toast.error('Enter a valid Email Address');
      setEmailHelperText('Enter a valid Email Address');
      return setErrorState({ ...errorState, emailAddressError: !validateEmailAddress(emailAddress) });
    } else if (validPhoneNumber(phoneNumber)) {
      toast.error('Enter a valid Phone Number');
      setPhoneNumHelperText('Enter a valid Phone Number');
      return setErrorState({ ...errorState, phoneNumberError: validPhoneNumber(phoneNumber) });
    } else if (contactPersonPhoneNumber?.length > 0 && validPhoneNumber(contactPersonPhoneNumber)) {
      toast.error('Enter a valid Phone Number');
      setEmailHelperText('Enter a valid Phone Number');
      return setErrorState({ ...errorState, contactPersonPhoneNumberError: validPhoneNumber(contactPersonPhoneNumber) });
    } else {
      return handleEditInfo();
    }
    return setErrorState({
      ...errorState,
      fullnameError: fullname === '',
      emailAddressError: emailAddress === '',
      phoneNumberError: phoneNumber === '',
      deliveryCityError: deliveryCity === '',
      deliveryAddressError: deliveryAddress === '',
      landmarkError: landmark === '',
      businessTypeError: businessType === '',
      businessNameError: businessName === '',
      salesRepError: salesRep === '',
    });
  };

  const validateState = (field, value) => {
    switch (field) {
      case 'fullname':
      case 'businessName':
      case 'salesRep':
      case 'deliveryCity':
      case 'deliveryAddress':
      case 'phoneNumber':
      case 'landmark':
      case 'businessType':
      case 'emailAddress':
        return setErrorState({
          ...errorState,
          [`${field}Error`]: !(value.length > 0)
        });
      default:
        return null;
    }
  };

  const handleChange = (event) => {
    const { name: fieldName, value } = event.target;
    let inputValue = value;
    if (fieldName === 'phoneNumber' || fieldName === 'contactPersonPhoneNumber') {
      inputValue = inputValue.replace(/[^0-9]/g, '');
    }

    if (fieldName === 'registrationNumber') {
      inputValue = inputValue.replace(/[^0-9/]/g, '');
    }
    validateState(fieldName, inputValue);
    setState({ ...state, [fieldName]: inputValue });
  };

  const handleSelectChange = (event) => {
    const { name: fieldName, value } = event.target;
    const valueMap = {
      Retail: 'RETAILER',
      Retail_Chain: 'RETAIL_CHAIN',
      Wholesale: 'WHOLESALER',
      Distributor: 'DISTRIBUTOR',
      Institution: 'INSTITUTION',
      Private_Hospital: 'PRIVATE_HOSPITAL',
      PPMV: 'PPMV',
    };
    const newValue = valueMap[value] || 'RETAILER';
    setState({ ...state, [fieldName]: newValue });
  };

  const formFields = [
    {
      name: 'fullname', label: 'Full Name', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g. Kings Pharmacy',
      error: fullnameError, helperText: 'Full Name is required'
    },
    {
      name: 'deliveryCity', label: 'Delivery City', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g. Ikeja',
      error: deliveryCityError, helperText: 'Delivery City is required'
    },
    {
      name: 'emailAddress', label: 'Email Address', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g. Maestropaul@example.com',
      error: emailAddressError, helperText: emailHelperText
    },
    {
      name: 'deliveryAddress', label: 'Delivery Address', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g. 10B Adeola Odeku Street, Victoria Island, Lagos State',
      error: deliveryAddressError, helperText: 'Delivery Address is required'
    },
    {
      name: 'phoneNumber', label: 'Phone Number', secured: !editing, show: true,
      required: true, type: 'number', sAdornment: phoneAdornment, placeholder: 'e.g 07012345678',
      error: phoneNumberError, helperText: phoneNumHelperText
    },
    {
      name: 'landmark', label: 'Landmark', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g. ICM Ikeja',
      error: landmarkError, helperText: 'Landmark is required'
    },
    {
      name: 'gender', label: 'Gender', placeholder: 'e.g. Male', required: false,
      options: ['Male', 'Female'], show: true, secured: !editing
    },
    {
      name: 'businessType', label: 'Business Type', placeholder: 'e.g. Retail',
      options: ['Retail', 'Retail_Chain', 'Wholesale', 'Distributor', 'Institution', 'Private_Hospital', 'PPMV'],
      show: true, secured: !editing, required: false, onChange: handleSelectChange,
    },
    {
      name: 'businessName', label: 'Business Name', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g Kings Pharmarcy',
      error: businessNameError, helperText: 'Business Name is required'
    },
    {
      name: 'contactPersonName', label: 'Contact Person Full Name', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g John Doe',
      error: contactPersonNameError, helperText: 'Contact Person Full Name is required'
    },
    {
      name: 'contactPersonPhoneNumber', label: 'Contact Person Phone Number', secured: !editing, show: true,
      required: false, type: 'number', sAdornment: phoneAdornment, placeholder: 'e.g. 07012345678'
    },
    {
      name: 'salesRep', label: 'Sales Representative', secured: !editing, show: true,
      required: true, type: 'text', placeholder: 'e.g John Doe',
      error: salesRepError, helperText: 'Sales Representative is required'
    },
    {
      name: 'deliveryState', label: 'Delivery State', secured: true, show: true,
      required: false, type: 'text', val: location
    },
    {
      name: 'creditLimit', label: 'Credit Limit (₦)', secured: !editing, show: true,
      required: false, type: 'number', val: location, placeholder: 'Enter credit limit'
    },
    {
      name: 'creditDays', label: 'Credit Days', secured: !editing, show: true,
      required: false, type: 'number', val: location, placeholder: 'Enter the amount of days for repayment'
    },
    {
      name: 'gracePeriod', label: 'Grace Period', secured: !editing, show: true,
      required: false, type: 'number', val: location, placeholder: 'Enter the amount of days'
    },
  ];

  const returnTextField = (field) => {
    const {
      name: fieldName, label, required, error, helperText, secured, placeholder, onChange, val, type, sAdornment
    } = field;

    const value = state[fieldName];

    if (['gender', 'businessType'].includes(fieldName)) {
      return (
        <CustomSelectInputBase
          field={field}
          value={value || val}
          disabled={secured}
          placeholder={placeholder}
          handleChange={onChange || handleChange}
          handleCreditDaysOpen={() => ({})}
          creditDays={() => ({})}
          showCheckBox={false}
          error={error || false}
          helperText={error && helperText}
          required={editing ? required : ''}
        />
      );
    }
    return (
      <CustomInputBase
        label={label}
        value={value || val}
        size="small"
        type={type || 'text'}
        onWheel={(e) => { e.target.blur(); }}
        error={error || false}
        helperText={error && helperText}
        loading={false}
        required={editing ? required : ''}
        disabled={secured}
        name={fieldName}
        onChange={handleChange}
        placeholder={placeholder}
        cSize="lg"
        readOnly={false}
        sAdornment={sAdornment}
      />
    );
  };

  const handleCloseMarkup = () => {
    setMarkupDialog(false);
  };

  const handleCloseConfirm = () => {
    setMarkupDialog(false);
    setConfirmDialog(false);
  };
  const handleCategoryReset = () => {
    const { credit_days: cDays, credit_limit: cLimit, grace_period: gPeriod } = parseJson(category?.creditWallet?.replace(/False/g, "'false'").replace(/True/g, "'true'"));
    return setState({
      ...state, creditDays: cDays, creditLimit: cLimit, gracePeriod: gPeriod
    });
  };

  return (
    <>
      <Grid container>
        <TitleBox container spacing={1}>
          <BoxGrid item xs={6} onClick={changeTab} active={tab === 'profile'}>
            <BoxTitleText active={tab === 'profile'}>Profile</BoxTitleText>
          </BoxGrid>
          <BoxGrid item xs={6} onClick={changeTab} active={tab === 'orders'}>
            <BoxTitleText active={tab === 'orders'}>Orders</BoxTitleText>
          </BoxGrid>
        </TitleBox>

        {catName && (
          <CategoryBox item onClick={() => setMarkupDialog(true)}>
            <CategoryName>
              <ProfileCategory style={{ fill: '#fff', fontSize: '1.2rem' }} />
              {catName}
            </CategoryName>
          </CategoryBox>
        )}
      </Grid>

      {tab === 'profile' ? (
        <>
          <HeaderBox>
            <BusinesssName>{name}</BusinesssName>
            <Grid container>
              <Grid item xs={6}>
                <VerifyBox>
                  <Bullet />
                  <VerifiedText>Account Verified</VerifiedText>
                </VerifyBox>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
                <EditButton
                  startIcon={
                    !editing ? (<Editicon style={{ fill: '#ffffff', fontSize: '1.5rem' }} />)
                      : (<SaveUpdateIcon style={{ fontSize: '1.5rem' }} />)
                    }
                  onClick={handleSaveButtonClick}
                >
                  {loading ? (
                    <CircularProgressLoader
                      disableShrink
                      size={22}
                      thickness={5}
                    />
                  ) : (
                    <EditButtonText>
                      {!editing ? 'Edit Profile Info' : 'Save Update'}
                    </EditButtonText>
                  )}
                </EditButton>
              </Grid>
            </Grid>
          </HeaderBox>

          <CreditContainer>
            <CreditGrid>
              <TextWrapper>
                <CreditText>Credit Limit (₦) </CreditText>
                <CreditValue>
                  :
                  {' '}
                  {creditLimit?.toLocaleString('en-US')}
                </CreditValue>
              </TextWrapper>
              <TextWrapper>
                <CreditText>Credit  Days</CreditText>
                <CreditValue>
                  :
                  {' '}
                  {creditDays}
                  {' '}
                  Days
                </CreditValue>
              </TextWrapper>
              <TextWrapper>
                <CreditText>Grace Period </CreditText>
                <CreditValue>
                  :
                  {' '}
                  {gracePeriod}
                  {' '}
                  Days
                </CreditValue>
              </TextWrapper>
              <TextWrapper>
                <CreditText>Category </CreditText>
                <CreditValue>
                  :
                  {' '}
                  {catName}
                </CreditValue>
              </TextWrapper>
            </CreditGrid>

            {editing && (
              <Reset onClick={handleCategoryReset}>
                <SyncIcon style={{ fontSize: '.8rem' }} />
                Reset to default category limit
              </Reset>
            ) }

          </CreditContainer>

          <Grid container spacing={3} style={{ paddingLeft: '1.5rem' }}>
            {formFields.map((field) => (
              <Grid item key={field?.name} xs={6}>{returnTextField(field)}</Grid>
            ))}
          </Grid>
        </>
      ) : (
        <BuyersOrder id={+businessId} />
      )}

      <SuccessDialog
        openDialog={openDialog}
        setOpenDialog={() => setOpenDialog(false)}
        title="Details Saved"
        desc="Your details has been updated successfully!"
        option="Ok"
      />

      <BuyerMarkup
        openDialog={markupDialog}
        setOpenDialog={handleCloseMarkup}
        location={location}
        markup={markup}
        setMarkup={setMarkup}
        confirmDialogHandler={() => setConfirmDialog(true)}
      />

      <ConfirmDialog
        openDialog={confirmDialog}
        setOpenDialog={handleCloseConfirm}
        title="Markup Update"
        desc="Are you sure you want to change Mark up for this buyer?"
        options={['Cancel', 'Yes, Update']}
        buttonAction={handleUpdateMarkup}
      />

      <SuccessDialog
        openDialog={successDialog}
        setOpenDialog={() => setSuccessDialog(false)}
        title="Successful!"
        desc="Markup has been updated successfully!"
        option="Ok"
      />
    </>
  );
};

BuyerProfile.propTypes = {
  userData: PropTypes.instanceOf(Object).isRequired,
  businessId: PropTypes.number.isRequired,
  accountType: PropTypes.string.isRequired,
  tab: PropTypes.string.isRequired,
  changeTab: PropTypes.func.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
};

export default BuyerProfile;

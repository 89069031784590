import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  DialogActions, Slide, Grid, Box
} from '@mui/material';
import { useMutation } from '@apollo/client';
import toast from 'react-hot-toast';
import CustomButton from '../../../customComponents/customButton';
import {
  CDialog, DialogTitleText, CircularProgressLoader, TextFieldBox, CTextField,
  GridWrapper, CloseIcon, FieldHeader
} from './createCreditLimiDialog.styles';
import SuccessDialog from '../../indevidualCategory/successDialog';
import { CREATE_CATEGORY_CREDIT_SCORE_MUTATION } from '../../../../mutations/categories';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" ref={ref} {...props} />
));

const CreateCreditLimiDialog = ({
  open, close, category, refetch
}) => {
  const initialState = {
    categoryName: '', creditLimit: '', creditDays: '', gracePeriod: '', categoryId: '', markup: ''
  };

  const [state, setState] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const [createBusinessCategory] = useMutation(CREATE_CATEGORY_CREDIT_SCORE_MUTATION);

  const {
    categoryName, creditLimit, creditDays, gracePeriod, categoryId, markup
  } = state;

  const textFieldHeader = [
    {
      name: 'categoryName', type: 'text',
      label: 'Category Name', placeholder: 'Enter Category Name'
    },
    {
      name: 'creditLimit', type: 'number',
      label: 'Credit Limit (₦) ', placeholder: 'Enter credit limit for this category'
    },
    {
      name: 'creditDays', type: 'number',
      label: 'Credit Days', placeholder: 'Enter the amount of days for repayment'
    },
    {
      name: 'gracePeriod', type: 'number',
      label: 'Grace Period', placeholder: 'Enter the amount of days'
    },
  ];

  useEffect(() => {
    if (category) {
      setState({
        ...state, categoryName: category?.name, categoryId: category?.id, markup: category.markup
      });
    }
  }, [category]);
  const handleSuccessDialog = () => {
    setSuccessDialog(!successDialog);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setState({ ...state, [name]: value });
  };

  const handleCreate = () => {
    if (categoryName === '' || creditLimit === '' || creditDays === '' || gracePeriod === '') return toast.error('Fields cannot be empty');
    setLoading(true);
    const creditWallet = {
      creditDays: Number(creditDays), gracePeriod: Number(gracePeriod), creditLimit: Number(creditLimit),
    };
    const variables = {
      categoryId: Number(categoryId), categoryName, markup, creditWallet
    };
    createBusinessCategory({ variables })
      .then(({ data }) => {
        const { message } = data?.createBusinessCategory || {};
        toast.success(message);
        setState(initialState);
        setSuccessDialog(true);
        refetch();
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleSuccessModal = () => {
    handleSuccessDialog();
    close();
  };

  const textField = (field) => {
    const {
      name: fieldName, shrink, type, placeholder
    } = field;
    const _value = state[fieldName];
    return (
      <TextFieldBox>
        <CTextField
          variant="outlined"
          value={_value}
          size="large"
          name={fieldName}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          fullWidth
          required
          disabled={fieldName === 'categoryName'}
          className={`mpAdmin-uat-customerCategory-add-category-${fieldName}`}
          InputProps={{
            style: { height: '3.5rem', fontSize: '.9rem', borderRadius: '.6rem' }
          }}
          InputLabelProps={{
            style: { fontSize: '.9rem', textAlign: 'center' },
            shrink
          }}
        />
      </TextFieldBox>
    );
  };
  const handleCloseDialog = () => {
    setState(initialState);
    close();
  };

  return (
    <CDialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      maxWidth="sm"
      fullWidth
    >
      <GridWrapper container>
        <Grid container style={{ marginTop: '2rem', padding: '0rem 1.5rem 1rem 1.5rem' }}>
          <Grid item xs={6}>
            <DialogTitleText container item xs={12}>
              Add Credit Limit
            </DialogTitleText>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <CloseIcon onClick={handleCloseDialog} />
          </Grid>
        </Grid>
        <Box>
          {textFieldHeader.map((fields) => (
            <Box key={fields}>
              <FieldHeader>
                {fields.label}
              </FieldHeader>
              {textField(fields)}
            </Box>
          ))}
        </Box>

        <DialogActions>
          <CustomButton
            type="tertiary"
            style={{
              width: '11rem', height: '3.2rem', marginRight: '1.2rem'
            }}
            onClick={handleCloseDialog}
            className="mpAdmin-uat-customerCategory-add-category-cancel"
          >
            Cancel
          </CustomButton>
          <CustomButton
            style={{ width: '11rem', height: '3.2rem' }}
            onClick={handleCreate}
            className="mpAdmin-uat-customerCategory-add-category-save"
          >
            {loading ? (
              <CircularProgressLoader
                disableShrink
                size={22}
                thickness={5}
              />
            ) : (
              'save'
            ) }
          </CustomButton>
        </DialogActions>
      </GridWrapper>
      <SuccessDialog
        openConfirmation={successDialog}
        handleClose={toggleSuccessModal}
        title="Credit limit Added Successfully!"
        discreption="Credit limit has been successfully added to this category!"
      />
    </CDialog>
  );
};

CreateCreditLimiDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  category: PropTypes.instanceOf(Object).isRequired,
  refetch: PropTypes.func.isRequired
};

export default CreateCreditLimiDialog;
